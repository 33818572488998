var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contentContainer text-center   " }, [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-wrapper  flex-wrap justify-center" },
      [
        _c("p", { staticClass: "mt-6" }, [
          _vm._v(
            "Objective to create a parallax website with animation and transitions."
          )
        ]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "fatboy-home",
              src: "https://media.giphy.com/media/Yd6VTtx84w7jSCfIzQ/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("fatboy-home")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-6/12" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/fatboy-home.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/fatboy-home.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Desktop Version")]),
        _vm._v(" "),
        _c("p", { staticClass: "mt-6" }, [
          _vm._v(
            "Use JQuery to control scrolling movements and animation of the images."
          )
        ]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "m-fatboy-home",
              src: "https://media.giphy.com/media/LMutZLA3x61K9WvZXv/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("m-fatboy-home")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-1/4" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/m-fatboy-home.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/m-fatboy-home.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Mobile Version")]),
        _vm._v(" "),
        _c("p", { staticClass: "mt-8 text-xl" }, [
          _vm._v(" Overall Journey of the Website.")
        ]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "fatboy-two",
              src: "https://media.giphy.com/media/eru6zIoN0n0UiKKXRz/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("fatboy-two")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-6/12" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/fatboy-two.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/fatboy-two.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Desktop Version")]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "m-fatboy-two",
              src: "https://media.giphy.com/media/5GeDhrUno0BHN7YpZh/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("m-fatboy-two")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-1/4" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/m-fatboy-two.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/m-fatboy-two.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Mobile Version")]),
        _vm._v(" "),
        _vm._m(2)
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "text-1xl  fontSemiBold" }, [
      _vm._v(" Fatboy Website Revamp"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Developed the website with HTML , CSS and JQuery. "),
      _c("br"),
      _vm._v(" Supported with Animate On Scroll Library Plugin.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-10" }, [
      _vm._v("Visit "),
      _c(
        "a",
        {
          staticClass: "text-white underline",
          attrs: { href: "https://www.fbgroup.asia/ ", target: "_blank" }
        },
        [_vm._v("Fb Group\n        Asia")]
      ),
      _vm._v("\n      website to see more. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }