<template>
    <div>

        <div class="hidden md:block delay-300 w-full" :class="{ navHidden: hideNav, fadeIn: fadeNav}">

            <div class="flex pl-2 bgColorBeige h-16">
                <div class="relative">
                    <div class="home-icon lego-shape " :class="changeHomeColor">
                        <router-link class="z-70 relative text-center block " :to="{ name: 'Home' }">Home</router-link>
                    </div>
                </div>
            </div>

            <nav id="nav" class="z-10 naviWrapper absolute w-full bottom-0 flex justify-center pb-2.5 mt-3.5"
                :class="{ moveDown : fadeNav }">

                <div class="relative" v-for="item in navlinks" :key="item.name">
                    <!-- -->
                    <!-- testing making navi with same comoponents refer to vueschool router tutorial -->
                    <!-- https://vueschool.io/lessons/how-to-pass-vue-router-params-as-props-to-components -->
                    <!-- :to ="{name:'DestinationDetails', params:{id:item.name}}" -->
                    <div :class="item.classType"
                        class=" hover:bg-white block text-center mx-1.5 lego-shape duration-300 transition">
                        <router-link :to="item.path" class="block   my-1.5  py-2.5  w-32"> {{item.name}} </router-link>
                    </div>
                </div>
            </nav>
        </div>

        <!-- hamburger menu -->
        <div class="hamburgerMenu md:hidden  bgColorBeige bg-opacity-100" :class="{ navHidden: hideNav}">

            <div class=" fixed ml-1.5 top-0  z-50 ">
                <div class="  home-icon lego-shape " :class="changeHomeColor">
                    <router-link class="text-center block" :to="{ name: 'Home' }">Home</router-link>
                </div>
            </div>

            <div class="lego-shape z-100  btnMenu fixed flex flex-col justify-center items-center"
                :class="[btnMenuColor, { moveDown : fadeNav , iconClose: !showMobile }]"
                @click="showMobile =!showMobile;  controlNaviColor();">
                <div class="iconMenu"></div>
                <div class="iconMenu"></div>
                <div class="iconMenu"></div>
            </div>

            <!-- cant use class bind with string cause display is none before load -->
            <!--  :class="[changeHomeColor, {hidden:showMobile , fadeIn: showMobile }]" -->
            <div class="m-bgMenu h-screen w-full fixed sm:hidden z-50  fadeIn "
                :class="{hidden:showMobile , fadeIn: showMobile } " @click="showMobile =!showMobile;"></div>

            <div class="naviWrapper w-full fixed sm:hidden z-50 fadeIn" :class="{hidden:showMobile }">
                <div v-for="item in navlinks" :key="item.name" class=" m-btnNav relative w-2/5 ">
                    <div class="block lego-shape  text-center" :class="item.classType" @click="showMobile = true">
                        <router-link :to="item.path" class="block   my-1.5  py-2.5 "> {{item.name}} </router-link>
                    </div>
                </div>
            </div>
        </div>
        <!-- hamburger menu end -->
        <!-- <div class="sm:hidden fixed bottom-0 " >
            <p class="">footer</p>
        </div> -->


    </div>
</template>
<style>
    .navHidden {
        display: none !important;
    }
</style>
<script>
    import routesArray from '../../src/router/index.js'

    export default {
        name: 'navigation',
        data() {
            return {
                hideNav: true,
                fadeNav: false,
                showMobile: true,
                changeHomeColor: String,
                btnMenuColor: String,
                // destinationId: this.$route.param.id,
                dataRoute: this.$route.path,
                navlinks: routesArray.options.routes,
                // navlinks: routesArray.options.routes[0].children,
                navPath: [],
                colorBlack: false
            }
        },
        methods: {

            controlNaviColor: function () {

                const isRoutes = this.$route.path;

                switch (isRoutes) {
                    case "/about":
                        $(".lego-shape").removeClass("active");
                        $(".bg-blockYellow").addClass("active");
                        this.changeHomeColor = "homeYellow";

                        //mobile
                        this.btnMenuColor = "btnMenuYellow"
                        setTimeout(function () {
                            $(".m-bgMenu").addClass("bgColorYellow")
                        }, 300);
                        break;
                    case "/work":
                        $(".lego-shape").removeClass("active");
                        $(".bg-blockRed").addClass("active");
                        this.changeHomeColor = "homeRed";

                        this.btnMenuColor = "btnMenuRed"
                        setTimeout(function () {
                            $(".m-bgMenu").addClass("bgColorRed")
                        }, 300);

                        
                        break;
                    case "/contact":
                        $(".lego-shape").removeClass("active");
                        $(".bg-blockGreen").addClass("active");
                        this.changeHomeColor = "homeGreen";

                        this.btnMenuColor = "btnMenuGreen"
                        setTimeout(function () {
                            $(".m-bgMenu").addClass("bgColorGreen")
                        }, 300);

                        break;
                    default:
                        // console.log("Try looking up for a hint.");
                }
            },
            hideShowNavi: function () {
                if (this.$route.path === "/") {
                    this.hideNav = true;
                    this.fadeNav = false;
                    this.showMobile = true;
                } else {
                    this.fadeNav = true;
                    this.hideNav = false;
                }

            }
        },
        watch: {
            $route: function () {
                this.controlNaviColor();
                this.hideShowNavi();

                // Check if given route is true, if it is then hide Nav. 
            }
        },
        mounted() {
            //remove Home button in array 
            this.navlinks.shift();
            this.controlNaviColor();
            this.hideShowNavi();

            // testing parse array object
            // var parsedobj = JSON.parse(JSON.stringify(routesArray.options.routes))
            // console.log("parsedobj", parsedobj[0].name)

        }
    }
</script>