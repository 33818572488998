var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "bg-color-beige delay-500 duration-200 ease-out fadeIn flex flex-col h-screen items-center justify-evenly transition ",
        class: _vm.bgColor
      },
      [
        _c(
          "div",
          { staticClass: "flex justify-center relative w-full bottom-16 " },
          _vm._l(_vm.mainButton, function(item) {
            return _c(
              "div",
              {
                key: item.name,
                staticClass:
                  "absolute hover:bg-white block text-center  lego-shape duration-300 transition ease-out ",
                class: item.classType,
                on: {
                  mouseover: function($event) {
                    _vm.bgColor = item.getBgColor
                    _vm.mouseOver()
                    _vm.textColor = item.getTextColor
                  },
                  mouseleave: function($event) {
                    _vm.bgColor = "bg-color-beige"
                    _vm.mouseLeave()
                    _vm.textColor = "colorDarkGrey transition delay-700"
                  }
                }
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "block   my-1.5  py-3.5 mt-1.5 w-36",
                    attrs: { to: item.path }
                  },
                  [_vm._v(_vm._s(item.name))]
                )
              ],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("router-view")
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "absolute text-center  w-full bottom-0 p-3" }, [
      _c(
        "p",
        {
          staticClass: "0xl:text-lg fontSemiBold sm:fontBold ",
          class: _vm.textColor
        },
        [_vm._v("KAR YANN . FRONT-END DEV . UI/UX DESIGNER ")]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "text-sm pb-10 pt-10", class: _vm.textColor }, [
        _vm._v(" Created by Kar Yann | "),
        _c(
          "a",
          {
            staticClass: "text-xs",
            class: _vm.textColor,
            attrs: {
              target: "_blank",
              href:
                "https://www.dropbox.com/scl/fi/cibjj9pss5qq8ojf7t229/HorKarYann_Front-End-Developer_2024.pdf?rlkey=jg9p8mj7fhap8mdcdqtvgfevx&st=9do6pr3s&dl=0"
            }
          },
          [_vm._v("\n        CV")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }