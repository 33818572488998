import { render, staticRenderFns } from "./p95-m-b-g.vue?vue&type=template&id=02b42b4e&"
import script from "./p95-m-b-g.vue?vue&type=script&lang=js&"
export * from "./p95-m-b-g.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\user\\Documents\\Projects\\portfolio-2020\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('02b42b4e')) {
      api.createRecord('02b42b4e', component.options)
    } else {
      api.reload('02b42b4e', component.options)
    }
    module.hot.accept("./p95-m-b-g.vue?vue&type=template&id=02b42b4e&", function () {
      api.rerender('02b42b4e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dev-projects/p95-m-b-g.vue"
export default component.exports