<template>
  <div class="contentContainer text-center   ">
    <h2 class="text-1xl leading-6 fontSemiBold">Breeze Pulangan Dijamin <br>
      <span class="text-tiny">(Lauch Campaign For Detergent)</span>
    </h2>
    <div class="content-wrapper  flex-wrap justify-center">

      <p class="mt-6">Developed the website with HTML, CSS and Javascript.</p>


      <imgGifComponent>
        <img id="breeze_pulangan" @click="replayGif('breeze_pulangan')"
          src="https://media.giphy.com/media/UjkDMU6XsS9ApgJuJk/giphy.gif">
      </imgGifComponent>
      <vidComponent>
        <video poster="../../assets/images/breeze_pulangan.jpg" controls>
          <source src="../../assets/videos/breeze_pulangan.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p class="mt-6">Desktop Version</p>


      <imgGifComponent>
        <img id="m-breeze_pulangan" @click="replayGif('m-breeze_pulangan')"
          src="https://media.giphy.com/media/sn7QCbBceRgkQidUcu/giphy.gif">
      </imgGifComponent>
      <vidComponent>
        <video poster="../../assets/images/m-breeze_pulangan.jpg" controls>
          <source src="../../assets/videos/m-breeze_pulangan.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p class="mt-6">Mobile Version</p>

    </div>


  </div>
</template>
<script>
  import clickMixin from '../../app/clickFunctions.js';
  import imgGifComponent from 'myComponents/imageGifComponent.vue';
  import vidComponent from 'myComponents/videoComponent.vue';

  export default {
    name: 'breeze',
    mixins: [clickMixin],
    components: {
      'vidComponent': vidComponent,
      'imgGifComponent': imgGifComponent,
    },
    data() {
      return {}
    },
    methods: {},
    created() {
      this.$emit('getModalText', 'bgProjColors breezeColor')
    }
  }
</script>