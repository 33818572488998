var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contentContainer text-center   " }, [
    _c("h2", { staticClass: "text-1xl fontSemiBold" }, [
      _vm._v("CIMB Website Revamp\n  ")
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-wrapper  flex-wrap justify-center" },
      [
        _c("p", { staticClass: "mt-6" }, [
          _vm._v(
            "Below are some of the workload where I built various types of component features that is reusable\n      throughout the\n      website."
          )
        ]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "cimb-one",
              src: "https://media.giphy.com/media/JibSXEjaBOiBIMQb3r/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("cimb-one")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-6/12" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/cimb-one.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/cimb-one.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Desktop Version")]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "m-cimb-one",
              src: "https://media.giphy.com/media/d3RfLzpMFytKE8Z4TL/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("m-cimb-one")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-1/4" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/m-cimb-one.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/m-cimb-one.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Mobile Version")]),
        _vm._v(" "),
        _c("p", { staticClass: "mt-10" }, [
          _vm._v(
            "Building components that is both responsive and flexible to cater different types of layout. "
          )
        ]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "cimb-two",
              src: "https://media.giphy.com/media/twkWyAp6XDY4e3lXGG/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("cimb-two")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-6/12" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/cimb-two.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/cimb-two.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Desktop Version")]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "m-cimb-two",
              src: "https://media.giphy.com/media/xQMdwt7842dkhMtWSG/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("m-cimb-two")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-1/4" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/m-cimb-two.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/m-cimb-two.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Mobile Version")]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "cimb-three",
              src: "https://media.giphy.com/media/NQzMlcKpDPwkNMqYob/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("cimb-three")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", {}, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/cimb-three.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/cimb-three.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Desktop Version")]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "m-cimb-three",
              src: "https://media.giphy.com/media/SWCL0VsxatSXjgmjUo/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("m-cimb-three")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-1/4" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/m-cimb-three.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/m-cimb-three.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Mobile Version")]),
        _vm._v(" "),
        _vm._m(1)
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-6" }, [
      _vm._v("Worked with a group of Front-End and Back-End developers. "),
      _c("br"),
      _vm._v(
        " Using HTML, Tailwind, JQuery, VueJS,\n    SwiperJs\n    and AEM CMS."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-10" }, [
      _vm._v("Visit "),
      _c(
        "a",
        {
          staticClass: "text-white underline",
          attrs: {
            href: "https://www.cimb.com.my/en/personal/home.html",
            target: "_blank"
          }
        },
        [_vm._v("CIMB")]
      ),
      _vm._v(" website to see more. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }