var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contentContainer text-center  " }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-wrapper  flex-wrap justify-center" },
      [
        _c("p", { staticClass: "mt-6" }, [
          _vm._v("Developed the website with HTML, CSS and Javascript.")
        ]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "mbg",
              src: "https://media.giphy.com/media/bcKuMSm2uceEQoRKmC/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("mbg")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/mbg.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/mbg.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Desktop Version")]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "m-mbg",
              src: "https://media.giphy.com/media/pnOgtRiICvsm2A8hSO/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("m-mbg")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/m-mbg.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/m-mbg.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Mobile Version")])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "text-1xl leading-6 fontSemiBold" }, [
      _vm._v("Money Back Gurantee "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-tiny" }, [
        _vm._v("(Detergent Launch Campaign)")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }