var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [_vm._m(0), _vm._v(" "), _vm._m(1), _vm._v(" "), _c("btnBW")],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          " top-0 sm:top-2 fixed py-5 sm:py-4 text-center w-full z-40 bgColorBeige bg-opacity-100 sm:bg-opacity-0"
      },
      [
        _c("p", { staticClass: "text-yellow-600 font-bold sm:text-1xl" }, [
          _vm._v("What About Me?")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          " customHeight   pt-20 sm:pt-8 pb-10 sm:pb-4 px-8 sm:px-3 fadeIn "
      },
      [
        _c(
          "div",
          { staticClass: " text-center aboutWrapper contentWrapper   " },
          [
            _c("h2", { staticClass: "text-yellow-500 text-1xl font-bold " }, [
              _vm._v("Kar Yann")
            ]),
            _vm._v(" "),
            _c("p", [
              _c("span", { staticClass: "text-blue-700 font-semibold" }, [
                _vm._v("Front-End Dev")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "text-yellow-700 font-bold text-lg" }, [
                _vm._v(".")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "text-green-700" }, [
                _vm._v(" UI / UX Desgner")
              ])
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-red-600 font-bold mt-6 text-xl" }, [
              _vm._v("What Do I Love?")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-red-600 sm:text-lg" }, [
              _vm._v("Building . Problem Solving . Innovating . Exploring "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "text-tiny fontBold" }, [
                _vm._v("(All With A Dash Of Creativity!)")
              ])
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-red-700" }, [
              _vm._v("Lego . Music . Sports . Technology . Shiny Things")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "font-bold mt-6 text-green-800 text-xl" }, [
              _vm._v("What People Say About Me")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-green-800" }, [
              _vm._v("Chill . Funny. Trolly . Shy . Quiet . Sore Loser "),
              _c("span", { staticClass: "text-xs" }, [
                _vm._v("(At\n          Times)")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-7" }, [
              _c(
                "p",
                { staticClass: "font-bold mt-6 text-indigo-600 text-xl" },
                [_vm._v("What I Have Learnt")]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "mt-3" }, [
                _vm._v("Primary Languages "),
                _c("br"),
                _vm._v(" "),
                _c("span", { staticClass: "fontBold text-indigo-800" }, [
                  _vm._v(
                    "\n            HTML . CSS . Javascript . jQuery . VueJs\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mt-5" }, [
                _vm._v("\n          Secondary Languages "),
                _c("br"),
                _vm._v(" "),
                _c("span", { staticClass: "fontBold text-indigo-800" }, [
                  _vm._v(" PHP . SQL ")
                ])
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mt-5" }, [
                _vm._v("\n          Things I Use To Help Me Out "),
                _c("br"),
                _vm._v(" "),
                _c("span", { staticClass: "fontBold text-indigo-800" }, [
                  _vm._v(
                    "\n            Webpack . PostCSS . Tailwind . Stylus . NPM . Gulp . GIT . GitLab . SourceTree\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mt-5" }, [
                _vm._v("\n          Softwares & Managements "),
                _c("br"),
                _vm._v(" "),
                _c("span", { staticClass: "fontBold text-indigo-800" }, [
                  _vm._v(
                    "Visual Studio . AdobeXD . Photoshop . Illustrator . Flash"
                  )
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("span", { staticClass: "fontBold text-indigo-800" }, [
                  _vm._v("Project Jira . Trello . Slack . Zeplin ")
                ])
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mt-5" }, [
                _vm._v("\n          Trying To Explore More~ "),
                _c("br"),
                _vm._v(" "),
                _c("span", { staticClass: "fontBold text-indigo-400" }, [
                  _vm._v("\n            ReactJS . AngularJS\n          ")
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "w-full flex justify-center mt-10 mb-10" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-36 md:w-32 lego-shape bgStaticGreen relative"
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "block text-red-500 font-bold text-md my-4 ",
                        attrs: {
                          href:
                            "https://www.dropbox.com/s/vqgqy970crtupbz/KarYann_CV.pdf?dl=0",
                          target: "_blank"
                        }
                      },
                      [_vm._v("VIEW CV")]
                    )
                  ]
                )
              ]
            )
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }