<template>
  <div class="contentContainer text-center   ">
    <h2 class="text-1xl fontSemiBold">MSIG Revamp</h2>
    <p class="mt-6">Worked with a group of Front-End developers and liasing with client's Back-End developers.
      <br> Using HTML, CSS, JQuery and VueJS (to build form to pass data).</p>

    <div class="content-wrapper  flex-wrap justify-center">
      <p class="mt-6">Build various types pages. <br>Challenge was to make the table display be mobile and desktop
        friendly.</p>

    

      <imgGifComponent>
        <img id="msig-one" @click="replayGif('msig-one')"
          src="https://media.giphy.com/media/kskNopraFjp3lZC0MS/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-6/12">
        <video poster="../../assets/images/msig-one.jpg" controls>
          <source src="../../assets/videos/msig-one.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Desktop Version</p>

      <imgGifComponent>
        <img id="m-msig-one" @click="replayGif('m-msig-one')"
          src="https://media.giphy.com/media/DoCievnuAHgTXaNbw6/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-1/4">
        <video poster="../../assets/images/m-msig-one.jpg" controls>
          <source src="../../assets/videos/m-msig-one.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Mobile Version</p>

       <imgGifComponent>
        <img id="msig-two" @click="replayGif('msig-two')"
          src="https://media.giphy.com/media/yJataDw7BAalLWhIXw/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-6/12">
        <video poster="../../assets/images/msig-two.jpg" controls>
          <source src="../../assets/videos/msig-two.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Desktop Version</p>

      <p class="mt-6">VueJs was use to pass data from each steps.</p>

       <imgGifComponent>
        <img id="m-msig-two" @click="replayGif('m-msig-two')"
          src="https://media.giphy.com/media/3VY95SX4md9OptdZ1D/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-1/4">
        <video poster="../../assets/images/m-msig-two.jpg" controls>
          <source src="../../assets/videos/m-msig-two.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Mobile Version</p>

      <p class="mt-10">Visit <a class="text-blue-900 underline" href="https://takeiteasy.msig.com.my/msigonline/"
          target="_blank">MSIG</a> website to see more. </p>

    </div>

  </div>
</template>
</style>

<script>
  import clickMixin from '../../app/clickFunctions.js';
  import imgGifComponent from 'myComponents/imageGifComponent.vue';
  import vidComponent from 'myComponents/videoComponent.vue';
  export default {
    name: 'msig',
    mixins: [clickMixin],
    components: {
      'vidComponent': vidComponent,
      'imgGifComponent': imgGifComponent,
    },
    data() {
      return {}
    },
    methods: {},
    created() {
      this.$emit('getModalText', 'bgProjColors msigColor')
    }
  }
</script>