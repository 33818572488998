


// local js for reusable function
export default {
  methods: {
    replayGif(value) {
      var getThis = value;
      // console.log("cick mixin", value)
      var getSrc =  $("#"+getThis+ "").attr("src");
      // console.log("cick arc ", getSrc)
      $("#"+getThis+ "").attr("src", getSrc) 
    }
  }
};