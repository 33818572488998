<template>
  <div class="contentContainer text-center   ">
    <h2 class="text-1xl leading-6 fontSemiBold"> Coffee Bean Tea Leaf Application<br>
      <span class="text-tiny">(University Assignment Prototype Development For Testing Purposes)</span>
    </h2>

    <div class="content-wrapper  flex-wrap justify-center">

      <p class="mt-6">Design and Developed the prototype with Photoshop and Adobe Flash to be test on android ios.</p>

      <img  class="inline-block mt-6 0xl:w-1/2" src="../../assets/images/CBTL_behance.png">

    </div>

  </div>
</template>
<script>
  import clickMixin from '../../app/clickFunctions.js'
  export default {
    name: 'cbtl',
    mixins: [clickMixin],
    data() {
      return {}
    },
    methods: {},
    created() {
      this.$emit('getModalText', 'bgProjColors cbtlColor')
    }
  }
</script>