<template>
  <div class="contentContainer text-center   ">
    <h2 class="text-1xl fontSemiBold">USG Boral Website Revamp
    </h2>
    <p class="mt-6">Worked with a group of Front-End and Back-End developers. <br> Using HTML, Less, JQuery, SwiperJs and AEM CMS.
    </p>

    <div class="content-wrapper  flex-wrap justify-center">
      <p class="mt-6">Below are some of the workload where I built various types of component features that is reusable throughout the
        website.</p>


      <div>
        <img class="inline-block mt-6 md:w-1/2" src="../../assets/images/usgboral/d-product-details-1.png">
      </div>

      <div>
        <img class="inline-block mt-6 md:w-1/4" src="../../assets/images/usgboral/m-product-details-1.png">
      </div>

      <div>
        <img class="inline-block mt-6 md:w-1/2" src="../../assets/images/usgboral/d-product-details-2.png">
      </div>
      <div>
        <img class="inline-block mt-6 md:w-1/4" src="../../assets/images/usgboral/m-product-details-2.png">
      </div>

      <div>
        <img class="inline-block mt-6 md:w-1/2" src="../../assets/images/usgboral/d-product-details-3.png">
      </div>
      <div>
        <img class="inline-block mt-6 md:w-1/4" src="../../assets/images/usgboral/m-product-details-3.png">
      </div>

      <div>
        <img class="inline-block mt-6 md:w-1/2" src="../../assets/images/usgboral/d-product-details-4.png">
      </div>
      <div>
        <img class="inline-block mt-6 md:w-1/4" src="../../assets/images/usgboral/m-product-details-4.png">
      </div>


      <div>
        <img class="inline-block mt-6 md:w-1/2" src="../../assets/images/usgboral/d-product-details-5.png">
      </div>

      <p class="mt-6"> Some components that requires filter function "Refine Your Search" where I will liase with Back-End developers to fetch data from the server.</p>

      <div>
        <img class="inline-block mt-6 md:w-1/4" src="../../assets/images/usgboral/m-product-details-5.png">
      </div>

      <div>
        <img class="inline-block mt-6 md:w-1/2" src="../../assets/images/usgboral/d-product-details-6.png">
      </div>

      <p class="mt-6">Compare product where I target and fetch data of each product to combine into an array to be displayed.</p>

      <div>
        <img class="inline-block mt-6 md:w-1/4" src="../../assets/images/usgboral/m-product-details-6.png">
      </div>

      <div>
        <img class="inline-block mt-6 md:w-1/2" src="../../assets/images/usgboral/d-gallery-1.jpg">
      </div>

      <div>
        <img class="inline-block mt-6 md:w-1/2" src="../../assets/images/usgboral/d-gallery-2.jpg">
      </div>



    </div>


  </div>
</template>
</style>

<script>
  import clickMixin from '../../app/clickFunctions.js'
  export default {
    name: 'usgb',
    mixins: [clickMixin],
    data() {
      return {}
    },
    methods: {},
    created() {
      this.$emit('getModalText', 'bgProjColors usgbColor')
    }
  }
</script>