var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal" }, [
    _c(
      "div",
      {
        staticClass: "btnClose  ",
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.closeModal($event)
          }
        }
      },
      [_vm._m(0)]
    ),
    _vm._v(" "),
    _c("div", [_vm._t("default")], 2)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "blockClose" }, [_c("p", [_vm._v("X")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }