<template>
  <div>

    <!-- 
    <div class=" top-3 fixed py-4 text-center w-full z-50">
      <p class="text-green-700 font-bold sm:text-1xl"> Contact Me :D </p>
    </div> -->

    <div class=" top-0 sm:top-2 fixed py-5 sm:py-4 text-center w-full z-40 bgColorBeige bg-opacity-100 sm:bg-opacity-0">
      <p class="text-green-700 font-bold sm:text-1xl">Contact Me :D</p>
    </div>



    <div class="customHeight flex items-center justify-center fadeIn ">

      <div class=" pt-20 sm:pt-10 sm:pb-36  px-6 sm:px-3 text-center contactWrapper contentWrapper">

        <p class="text-red-600 font-bold mt-8">Preferably</p>
        <p class="text-red-600 "> kyann007@gmail.com<br><span class="text-xs fontBold">(Promise To Reply)</span></p>

        <!-- <p class="text-blue-700 font-bold mt-6">Services</p>
        <p class="text-blue-700">Web Development
          <br> UI / UX Design <br> Web Design</p> -->

        <p class="text-purple-700 font-bold mt-6">I Am Feeling Adventurous!</p>
        <p class="text-purple-700">Interested to try and take on new interactive projects!</p>

        <p class="text-green-700 font-bold mt-6">Stay Connected With Me</p>
        <p class="text-green-700"><a class="text-green-600" target="_blank"
            href="https://www.linkedin.com/in/kar-yann-16693a47/">Linkedin</a> . <a class="text-green-600"
            target="_blank" href="https://www.behance.net/karyann">Behance</a>
        </p>

        <p class="text-red-700 mt-6">Check out my CV for more details!</p>
        <a class="text-yellow-500 font-bold"
          href="https://www.dropbox.com/scl/fi/cibjj9pss5qq8ojf7t229/HorKarYann_Front-End-Developer_2024.pdf?rlkey=jg9p8mj7fhap8mdcdqtvgfevx&st=9do6pr3s&dl=0"
          target="_blank">View CV</a><br>

      </div>

    </div>

    <!-- blackwhite btn -->
    <btnBW />

  </div>
</template>

<script>
  import buttonBW from 'myComponents/buttonBW.vue';

  export default {
    name: 'Contact',
    components: {
      'btnBW': buttonBW,
    },
    data() {
      return {
        email: 'maegan@maeganwilson.com',
        web: 'maeganjwilson.github.com'
      }
    },
    methods: {

    }
  }
</script>