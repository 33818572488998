<template>
  <div class="contentContainer text-center   ">
    <h2 class="text-1xl fontSemiBold">Proton X70 Promotional Microsite </h2>
    <p class="mt-6">Build with HTML, CSS, JQuery and Sitecore CMS.</p>

    <div class="content-wrapper  flex-wrap justify-center">

      <imgGifComponent>
        <img id="proton-one" @click="replayGif('proton-one')"
          src="https://media.giphy.com/media/OVaCea5Emu4fXXgFui/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-6/12">
        <video poster="../../assets/images/proton-one.jpg" controls>
          <source src="../../assets/videos/proton-one.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Desktop Version</p>

      <imgGifComponent>
        <img id="m-proton-one" @click="replayGif('m-proton-one')"
          src="https://media.giphy.com/media/NUW8wLvm3IxtRxj8Is/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-1/4">
        <video poster="../../assets/images/m-proton-one.jpg" controls>
          <source src="../../assets/videos/m-proton-one.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Mobile Version</p>


    </div>


  </div>
</template>
</style>

<script>
  import clickMixin from '../../app/clickFunctions.js';
  import imgGifComponent from 'myComponents/imageGifComponent.vue';
  import vidComponent from 'myComponents/videoComponent.vue';
  export default {
    name: 'proton',
    mixins: [clickMixin],
    components: {
      'vidComponent': vidComponent,
      'imgGifComponent': imgGifComponent,
    },
    data() {
      return {}
    },
    methods: {},
    created() {
      this.$emit('getModalText', 'bgProjColors protonColor')
    }
  }
</script>