<template>
  <div class="contentContainer text-center ">

    <h2 class="text-1xl leading-6 fontSemiBold">Magnum Pink and Black <br>
      <span class="text-tiny">(New Magnum Flavor Launch Campaign)</span>
    </h2>

    <div class="content-wrapper  flex-wrap justify-center">


      <p class="mt-6">Developed the website with HTML, CSS and Javascript.</p>


      <imgGifComponent>
        <img id="magnum" @click="replayGif('magnum')" src="https://media.giphy.com/media/2guaybO7QPTHsisTeG/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-6/12">
        <video poster="../../assets/images/magnum.jpg" controls>
          <source src="../../assets/videos/magnum.mp4" type="video/mp4">
        </video>
      </vidComponent>

      <p>Desktop Version</p>

      <p class="mt-6">Developed the transitions and features of the Contest page with JQuery and CSS to flow smoothly.
      </p>
      <img class="inline-block mt-6" src="../../assets/images/m-magnum.jpg">
      <p>Mobile Version</p>

      <p class="text-sm">Images
        Source<br>http://www.kitepunye.com/event/magnum-pink-black-yang-mana-satu-mood-anda/</p>

      <p class="mt-6">(Worked in a team of 2 liasing with backend developers.)</p>

    </div>


  </div>
</template>

<script>
  import clickMixin from '../../app/clickFunctions.js';
  import imgGifComponent from 'myComponents/imageGifComponent.vue';
  import vidComponent from 'myComponents/videoComponent.vue';

  export default {
    name: 'p2magnum',
    mixins: [clickMixin],
    components: {
      'vidComponent': vidComponent,
      'imgGifComponent': imgGifComponent,
    },
    data() {
      return {}
    },
    created() {
      this.$emit('getModalText', 'bgProjColors magnumColor')
    }
  }
</script>