<template>
  <div>

    <div
      class="bg-color-beige delay-500 duration-200 ease-out fadeIn flex flex-col h-screen items-center justify-evenly transition "
      :class="bgColor">
      <!-- <p class="absolute top-0 left-0"> LOGO</p> -->
      <div class="flex justify-center relative w-full bottom-16 ">
        <div class="absolute hover:bg-white block text-center  lego-shape duration-300 transition ease-out "
          v-for="item in mainButton" :key="item.name" :class="item.classType"
          @mouseover="bgColor = item.getBgColor; mouseOver(); textColor = item.getTextColor;"
          @mouseleave="bgColor ='bg-color-beige'; mouseLeave(); textColor ='colorDarkGrey transition delay-700';">
          <router-link class="block   my-1.5  py-3.5 mt-1.5 w-36" :to="item.path">{{item.name}}</router-link>
        </div>
      </div>

      <router-view></router-view>

    </div>


    <div class="absolute text-center  w-full bottom-0 p-3">
      <p :class="textColor" class="0xl:text-lg fontSemiBold sm:fontBold ">KAR YANN . FRONT-END DEV . UI/UX DESIGNER </p>
      <p :class="textColor" class="text-sm pb-10 pt-10"> Created by Kar Yann | <a class="text-xs" :class="textColor"
          target="_blank"
          href='https://www.dropbox.com/scl/fi/cibjj9pss5qq8ojf7t229/HorKarYann_Front-End-Developer_2024.pdf?rlkey=jg9p8mj7fhap8mdcdqtvgfevx&st=9do6pr3s&dl=0'>
          CV</a></p>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'Hello',
    data() {
      return {
        bgColor: String,
        textColor: String,
        mainButton: [{
          path: "/about",
          name: "Me",
          id: 0,
          classType: "bg-blockYellow stackBlockOne  ",
          getBgColor: "bg-yellow-300 ",
          getTextColor: "colorDarkGrey transition delay-300 "
        }, {
          path: "/work",
          name: "Work",
          id: 1,
          classType: "bg-blockRed stackBlockTwo",
          getBgColor: "bg-red-700",
          getTextColor: "colorWhite transition delay-300 "
        },
        {
          path: "/contact",
          name: "Contact",
          id: 2,
          classType: "bg-blockGreen stackBlockThree",
          getBgColor: "bg-green-700",
          getTextColor: "colorWhite transition delay-300"
        },
        ]
      }
    },
    methods: {
      mouseOver: function () {
        if (event.currentTarget.classList.contains('bg-blockRed')) {
          $(".bg-blockYellow").addClass("moveBlockTop")
          $(".bg-blockGreen").addClass("moveBlockBottom")
          // console.log("hover test")
        }
      },
      mouseLeave: function () {
        if (event.currentTarget.classList.contains('bg-blockRed')) {
          $(".bg-blockYellow").removeClass("moveBlockTop")
          $(".bg-blockGreen").removeClass("moveBlockBottom")
        }
      }

    },
    created() {

    }
  }
</script>