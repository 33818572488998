var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contentContainer text-center   " }, [
    _c("h2", { staticClass: "text-1xl fontSemiBold" }, [
      _vm._v("EASI Microsite ")
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "mt-6" }, [
      _vm._v(
        "Build with HTML, CSS, JQuery liasing with Back-End developers using AEM CMS."
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-wrapper  flex-wrap justify-center" },
      [
        _c("p", { staticClass: "mt-6" }, [
          _vm._v(
            "Build various types of components to be reusable throughout the website."
          )
        ]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "easi-one",
              src: "https://media.giphy.com/media/rj99OSovOuzEj0crNQ/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("easi-one")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-6/12" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/easi-one.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/easi-one.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Desktop Version")]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "m-easi-one",
              src: "https://media.giphy.com/media/ZkHypleMmqNfI6g7wv/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("m-easi-one")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-1/4" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/m-easi-one.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/m-easi-one.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Mobile Version")]),
        _vm._v(" "),
        _vm._m(0)
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-10" }, [
      _vm._v("Visit "),
      _c(
        "a",
        {
          staticClass: "text-white underline",
          attrs: { href: "https://www.easigypsum.com/", target: "_blank" }
        },
        [_vm._v("Easi")]
      ),
      _vm._v("\n      website to see more. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }