<template>
  <div class="contentContainer text-center   ">
    <h2 class="text-1xl fontSemiBold">MSIG Mobile App Slice</h2>
    <p class="mt-6">Build with HTML, CSS, JQuery. Liasting with client's side Mobile developers.</p>

    <div class="content-wrapper  flex-wrap justify-center">

      <imgGifComponent>
        <img id="msig-mobile-one" @click="replayGif('msig-mobile-one')"
          src="https://media.giphy.com/media/RxZqs6KGYBTK9VRDWy/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-1/4">
        <video poster="../../assets/images/msig-mobile-one.jpg" controls>
          <source src="../../assets/videos/msig-mobile-one.mp4" type="video/mp4">
        </video>
      </vidComponent>

      <p class="mt-10">Building features such as sliders , checkbox , accordions and forms.</p>

      <imgGifComponent>
        <img id="msig-mobile-two" @click="replayGif('msig-mobile-two')"
          src="https://media.giphy.com/media/l8hLpcgaHdeT0dwDVB/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-1/4">
        <video poster="../../assets/images/msig-mobile-two.jpg" controls>
          <source src="../../assets/videos/msig-mobile-two.mp4" type="video/mp4">
        </video>
      </vidComponent>

    </div>


  </div>
</template>
</style>

<script>
  import clickMixin from '../../app/clickFunctions.js';
  import imgGifComponent from 'myComponents/imageGifComponent.vue';
  import vidComponent from 'myComponents/videoComponent.vue';
  export default {
    name: 'msigApp',
    mixins: [clickMixin],
    components: {
      'vidComponent': vidComponent,
      'imgGifComponent': imgGifComponent,
    },
    data() {
      return {}
    },
    methods: {},
    created() {
      this.$emit('getModalText', 'bgProjColors msigAppColor')
    }
  }
</script>