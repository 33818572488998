<template>
  <div>


    <div class=" top-0 sm:top-2 fixed py-5 sm:py-4 text-center w-full z-40 bgColorBeige bg-opacity-100 sm:bg-opacity-0">
      <p class="text-yellow-600 font-bold sm:text-1xl">What About Me?</p>
    </div>


    <div class=" customHeight   pt-20 sm:pt-8 pb-10 sm:pb-4 px-8 sm:px-3 fadeIn ">

      <div class=" text-center aboutWrapper contentWrapper   ">

        <h2 class="text-yellow-500 text-1xl font-bold ">Kar Yann</h2>

        <p>
          <span class="text-blue-700 font-semibold">Front-End Dev</span>
          <span class="text-yellow-700 font-bold text-lg">.</span>
          <span class="text-green-700"> UI / UX Desgner</span>
        </p>

        <p class="text-red-600 font-bold mt-6 text-xl">What Do I Love?</p>
        <p class="text-red-600 sm:text-lg">Building . Problem Solving . Innovating . Exploring <br>
          <span class="text-tiny fontBold">(All With A Dash Of Creativity!)</span>
        </p>
        <p class="text-red-700">Lego . Music . Sports . Technology . Shiny Things</p>
        <!-- <p class="text-blue-600 font-bold mt-6">What Do I Like?</p> -->

        <!-- <p class="text-green-800 font-bold mt-6">Who Am I?</p>
        <p class="text-green-800">Just An Innocent Troll
          <span class="text-xs fontBold">(When I Am Not Working)</span><br>
          Serious Face <span class="text-xs fontBold">(When I Am Working)</span>
        </p> -->

        <p class="font-bold mt-6 text-green-800 text-xl">What People Say About Me</p>
        <!-- <p class="text-green-800">Just An Innocent Troll
          <span class="text-xs fontBold">(When I Am Not Working)</span><br>
          Serious Face <span class="text-xs fontBold">(When I Am Working)</span>
        </p> -->
        <p class="text-green-800">Chill . Funny. Trolly . Shy . Quiet . Sore Loser <span class="text-xs">(At
            Times)</span>
        </p>

        <div class="mt-7">
          <p class="font-bold mt-6 text-indigo-600 text-xl">What I Have Learnt</p>

          <p class="mt-3">Primary Languages <br>
            <span class="fontBold text-indigo-800">
              HTML . CSS . Javascript . jQuery . VueJs
            </span></p>
          <p class="mt-5">
            Secondary Languages <br>
            <span class="fontBold text-indigo-800"> PHP . SQL </span>
          </p>
          <p class="mt-5">
            Things I Use To Help Me Out <br>
            <span class="fontBold text-indigo-800">
              Webpack . PostCSS . Tailwind . Stylus . NPM . Gulp . GIT . GitLab . SourceTree
            </span>
          </p>
          <p class="mt-5">
            Softwares & Managements <br>
            <span class="fontBold text-indigo-800">Visual Studio . AdobeXD . Photoshop . Illustrator . Flash</span> <br>
            <span class="fontBold text-indigo-800">Project Jira . Trello . Slack . Zeplin </span>

          </p>
          <p class="mt-5">
            Trying To Explore More~ <br>
            <span class="fontBold text-indigo-400">
              ReactJS . AngularJS
            </span>
          </p>



        </div>

          <div class="w-full flex justify-center mt-10 mb-10">
            <div class="w-36 md:w-32 lego-shape bgStaticGreen relative">
              <a class="block text-red-500 font-bold text-md my-4 "
                href="https://www.dropbox.com/s/vqgqy970crtupbz/KarYann_CV.pdf?dl=0" target="_blank">VIEW CV</a>
            </div>
          </div>


      </div>

    </div>
    <btnBW> </btnBW>
  </div>
</template>



<script>
  import buttonBW from 'myComponents/buttonBW.vue';
  export default {
    name: 'About',
    components: {
      'btnBW': buttonBW,
    },
    data() {
      return {}
    },
    methods: {

    }
  }
</script>