var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loadingScreen
      ? _c(
          "div",
          {
            staticClass:
              "loadingScreen overflow-hidden absolute bg-green-700  h-screen top-0 w-full z-100"
          },
          [_vm._m(0)]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.loadWork
      ? _c(
          "div",
          {
            staticClass: "overflow-auto h-screen sm:overflow-hidden sm:h-auto"
          },
          [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "workWrapper pt-20 sm:pt-10  pb-10 duration-900 fadeInWork"
              },
              [
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      " fadeIn  justify-items-center  grid grid-cols-3 sm:grid-cols-5  px-0.5  sm:px-24   lg:px-32 xl:px-52   2xl:px-72 3xl:px-80 4xl:mx-52 4xl:px-80 pb-5  ",
                    attrs: { id: "devContainer" }
                  },
                  _vm._l(_vm.devList, function(item) {
                    return _c(
                      "div",
                      { key: item.id, class: item.number },
                      [
                        _c(
                          "modal",
                          {
                            staticClass: "modal-box",
                            class: item.color,
                            attrs: { title: item.project, message: item.title },
                            on: { "toggle-modal": _vm.listener }
                          },
                          [
                            _c(item.project, {
                              tag: "component",
                              on: { getModalText: _vm.getModalBgClassDev }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "relative mx-1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "lego-shape bg-blockYellow my-5 capitalize text-center flex  flex-col md:flex-row justify-center items-center fontSemiBold sm:text-sm lg:text-base btnPopModal ",
                              class: item.color,
                              on: { click: _vm.toggleModalPopup }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "logoImg mb-0.5 md:mb-0 md:mr-1.5 w-6 h-6"
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../assets/images/logo/" +
                                        item.imageSrc +
                                        ".jpg")
                                    }
                                  })
                                ]
                              ),
                              _vm._v(
                                "\n\n              " +
                                  _vm._s(item.title) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      " fadeIn  justify-items-center  grid grid-cols-3 sm:grid-cols-5  px-0.5  sm:px-24   lg:px-32 xl:px-52   2xl:px-72 3xl:px-80 4xl:mx-52 4xl:px-80  ",
                    attrs: { id: "designContainer" }
                  },
                  _vm._l(_vm.designList, function(item) {
                    return _c(
                      "div",
                      { key: item.id, class: item.number },
                      [
                        _c(
                          "modal",
                          {
                            staticClass: "modal-box",
                            class: item.color,
                            attrs: { title: item.project, message: item.title },
                            on: { "toggle-modal": _vm.listener }
                          },
                          [
                            _c(item.project, {
                              tag: "component",
                              on: { getModalText: _vm.getModalBgClassDesign }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "relative mx-1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "lego-shape bg-blockYellow my-5 capitalize text-center flex  flex-col md:flex-row  justify-center items-center fontSemiBold sm:text-sm lg:text-base btnPopModal ",
                              class: item.color,
                              on: { click: _vm.toggleModalPopup }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "logoImg mb-0.5 md:mb-0 md:mr-1.5 w-6 h-6"
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../assets/images/logo/" +
                                        item.imageSrc +
                                        ".jpg")
                                    }
                                  })
                                ]
                              ),
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.title) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  }),
                  0
                )
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "flex flex-col justify-center relative w-full h-screen items-center fadeIn "
      },
      [
        _c(
          "div",
          {
            staticClass:
              " animate-bounce\tbgStaticRed relative block text-center  pointer-events-none lego-shape delay-200 duration-200 transition ease-out "
          },
          [
            _c(
              "div",
              {
                staticClass:
                  " block   my-1.5  py-3.5 mt-1.5 w-36 text-white font-semibold"
              },
              [_vm._v("loading...")]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "text-white text-lg mt-10 text-center px-10 " },
          [
            _vm._v("Did you know? "),
            _c("br"),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "On average there are 80 LEGO\n        bricks for every person on earth!\n        "
            ),
            _c("br"),
            _vm._v(" Wow! Pretty amazing, huh?")
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "fixed top-0 sm:top-2  py-5 sm:py-4 text-center w-full z-40 bgColorBeige bg-opacity-100 sm:bg-opacity-0"
      },
      [
        _c("p", { staticClass: "text-red-600 font-bold sm:text-1xl" }, [
          _vm._v("Check Out My Projects!")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-full text-center" }, [
      _c(
        "h2",
        { staticClass: "fontBold text-lg mt-3 md:mt-0 mb-5 text-red-700" },
        [_vm._v("Development Projects")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-full text-center" }, [
      _c(
        "h2",
        { staticClass: "fontBold text-lg  mt-6 md:mt-3 mb-6 text-red-700" },
        [_vm._v("Design Projects")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }