var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contentContainer text-center  " }, [
    _c("h2", { staticClass: "text-1xl fontSemiBold " }, [
      _vm._v("Dove Project (Phase 1 & 2) ")
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-wrapper  flex-wrap justify-center" },
      [
        _c("h2", { staticClass: "text-xl my-6" }, [_vm._v("Phase One")]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "doveph1",
              src: "https://media.giphy.com/media/8MAGWUKDF1artAfhXg/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("doveph1")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-6/12" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/doveph1.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/doveph1.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Desktop Version")]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "doveph1_mobile",
              src: "https://media.giphy.com/media/X1ZyRwlkHNmeIdM4VU/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("doveph1_mobile")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/doveph1_mobile.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/doveph1_mobile.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Mobile Version")]),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "dove_phase2-1",
              src: "https://media.giphy.com/media/k9ij8e1CSGc7ZVCXJA/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("dove_phase2-1")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-6/12" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/dove_phase2-1.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/dove_phase2-1.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Desktop Version")]),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "dove_phase2-2",
              src: "https://media.giphy.com/media/3U0lG2s8xMFlDTHPnJ/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("dove_phase2-2")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/dove_phase2-2.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/dove_phase2-2.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Featured Pages : KOL Gallery Display , Book Details , Form Details."
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "mt-6" }, [
          _vm._v(
            "Manually coded the features of Gallery Display page with JQuery and CSS."
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "mt-3" }, [
          _vm._v(
            "Aligning the composition of text and image in the Book Detail's and Form pages to fit responsive\n      screen."
          )
        ]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "m-dove_ph2",
              src: "https://media.giphy.com/media/rEnnW9ZNS9mg9NiqrU/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("m-dove_ph2")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/m-dove_ph2.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/m-dove_ph2.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Mobile Version")]),
        _vm._v(" "),
        _c("p", { staticClass: "mt-6" }, [
          _vm._v("(Worked in a team of 2 liasing with backend developers.)")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Developed the website with HTML , CSS and Javascript."),
      _c("br"),
      _vm._v("Supported with Fullpage.js Plugin.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-6" }, [
      _vm._v("Animation is scripted with JS and Fullpage Js "),
      _c("br"),
      _vm._v("is used to control section scrolling.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "mt-10 text-xl leading-6" }, [
      _vm._v("Phase Two "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-tiny" }, [
        _vm._v("(This website is a continuation of Dove phase 1 campaign.)")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-6" }, [
      _vm._v("Animation is scripted with JS and Fullpage Js "),
      _c("br"),
      _vm._v("is used to control section scrolling.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }