
var $ = require( "jquery" );
// import testJS  from './app/test.js';


/* eslint-disable no-new */
// new Vue({
//   el: '#vueContent',
//     render: h => h(App)
// })




// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common.conf with an alias.
import Vue from 'vue'
import App from './app.vue'
import router from './router'
// import vuetify from './plugins/vuetify' // path to vuetify export
import './index.css';


Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  // vuetify,
  router,
  template: '<App/>',
  // render: h => h(App)
  components: { App }
})
// .$mount("#app")






// var firebase = require('firebase');
// var db = firebase.initializeApp({
//      databaseURL: 'https:mytesting.firebaseio.com'
// }).database()
// // Logical implementation