<template>
  <div>
    <!-- loading screen -->
    <div v-if="loadingScreen" class="loadingScreen overflow-hidden absolute bg-green-700  h-screen top-0 w-full z-100">

      <div class="flex flex-col justify-center relative w-full h-screen items-center fadeIn ">
        <!-- <div
          class=" animate-bounce	bgStaticGreen relative block text-center  pointer-events-none lego-shape duration-300 transition ease-out ">
          <div class=" block   my-1.5  py-3.5 mt-1.5 w-36">a</div>
        </div> -->
        <div
          class=" animate-bounce	bgStaticRed relative block text-center  pointer-events-none lego-shape delay-200 duration-200 transition ease-out ">
          <div class=" block   my-1.5  py-3.5 mt-1.5 w-36 text-white font-semibold">loading...</div>
        </div>

        <p class="text-white text-lg mt-10 text-center px-10 ">Did you know? <br> <br>On average there are 80 LEGO
          bricks for every person on earth!
          <br> Wow! Pretty amazing, huh?</p>
      </div>
    </div>
    <!-- loading screen end -->

    <!-- work  -->
    <div v-if="loadWork" class="overflow-auto h-screen sm:overflow-hidden sm:h-auto">

      <div
        class="fixed top-0 sm:top-2  py-5 sm:py-4 text-center w-full z-40 bgColorBeige bg-opacity-100 sm:bg-opacity-0">
        <p class="text-red-600 font-bold sm:text-1xl">Check Out My Projects!</p>
      </div>

      <div class="workWrapper pt-20 sm:pt-10  pb-10 duration-900 fadeInWork">

        <div class="w-full text-center">
          <h2 class="fontBold text-lg mt-3 md:mt-0 mb-5 text-red-700">Development Projects</h2>
        </div>

        <!-- devWork component list  -->
        <div id="devContainer"
          class=" fadeIn  justify-items-center  grid grid-cols-3 sm:grid-cols-5  px-0.5  sm:px-24   lg:px-32 xl:px-52   2xl:px-72 3xl:px-80 4xl:mx-52 4xl:px-80 pb-5  ">

          <div v-for="item in devList" :key="item.id" :class="item.number">

            <modal :title="item.project" class="modal-box" @toggle-modal="listener" :message="item.title"
              :class="item.color">
              <component v-bind:is="item.project" @getModalText="getModalBgClassDev"> </component>
            </modal>

            <div class="relative mx-1">
              <div
                class="lego-shape bg-blockYellow my-5 capitalize text-center flex  flex-col md:flex-row justify-center items-center fontSemiBold sm:text-sm lg:text-base btnPopModal "
                :class="item.color" @click="toggleModalPopup">
                <div class="logoImg mb-0.5 md:mb-0 md:mr-1.5 w-6 h-6">
                  <!-- <img :src="require('../assets/images/logo/' + item.imageSrc + '.jpg')" /> -->
                  <img :src='"../assets/images/logo/" + item.imageSrc + ".jpg"' />
                </div>

                {{item.title}}
              </div>
            </div>

          </div>
        </div>
        <!-- devWork component list end -->

        <!-- design component list  -->
        <div class="w-full text-center">
          <h2 class="fontBold text-lg  mt-6 md:mt-3 mb-6 text-red-700">Design Projects</h2>
        </div>
        <div id="designContainer"
          class=" fadeIn  justify-items-center  grid grid-cols-3 sm:grid-cols-5  px-0.5  sm:px-24   lg:px-32 xl:px-52   2xl:px-72 3xl:px-80 4xl:mx-52 4xl:px-80  ">

          <div v-for="item in designList" :key="item.id" :class="item.number">

            <modal :title="item.project" class="modal-box" @toggle-modal="listener" :message="item.title"
              :class="item.color">
              <component v-bind:is="item.project" @getModalText="getModalBgClassDesign"> </component>
            </modal>

            <div class="relative mx-1">
              <div
                class="lego-shape bg-blockYellow my-5 capitalize text-center flex  flex-col md:flex-row  justify-center items-center fontSemiBold sm:text-sm lg:text-base btnPopModal "
                :class="item.color" @click="toggleModalPopup">
                <div class="logoImg mb-0.5 md:mb-0 md:mr-1.5 w-6 h-6">
                  <!-- <img :src="require('../assets/images/logo/' + item.imageSrc + '.jpg')" /> -->
                  <img :src='"../assets/images/logo/" + item.imageSrc + ".jpg"' />
                </div>
                {{item.title}}
              </div>
            </div>

          </div>
        </div>
        <!-- design component list end -->



      </div>

    </div>
    <!--work end -->

  </div>
</template>


<script>
  // import components without manually listing in the components object
  import 'myComponents/dev-projects/_devComponents';
  import 'myComponents/design-projects/_designComponents';
  import Modal from 'myComponents/componentModal.vue';


  export default {
    name: 'About',
    components: {
      'Modal': Modal,
    },
    props: {
      modalName: String,
    },
    data() {
      return {

        loadWork: true,
        loadingScreen: false,

        devList: String,
        getModalText: String,
        arrModalColorDev: [],
        getAllClassDev: "",

        designList: String,
        arrModalColorDesign: [],
        getAllClassDesign: "",

        getFileList: String,
        combineArrayList: String,
      }
    },
    methods: {
      // getImgUrl(pet) {
      //   var images = require.context('../assets/images/logo', false, /\.jpg$/)
      //   return images('src/assets/images/logo/' + pet + ".jpg")
      // },
      toggleModalPopup() {
        // this.isActive = !this.isActive;
        //target parent class
        let getClass = event.currentTarget.parentElement.parentElement.classList.value;
        let getIDContainer = event.currentTarget.parentElement.parentElement.parentElement.id;

        let $targetBox = $("#" + getIDContainer + "").find("." + getClass + " .modal-box");
        $("body").removeClass("blackWhite")
        $targetBox.addClass("show fadeIn");
        $targetBox.removeClass("fadeOut");
      },
      listener() {
        let getParClass = event.currentTarget.parentElement.parentElement.classList.value;
        let getIDContainer = event.currentTarget.parentElement.parentElement.parentElement.id;

        let $targetBox = $("#" + getIDContainer + "").find("." + getParClass + " .modal-box");
        $targetBox.addClass("fadeOut");
        setTimeout(function () {
          $targetBox.removeClass("show fadeIn");
        }, 395);
      },
      getModalBgClassDev(devName) {
        // get emit data from each project component class
        this.arrModalColorDev.push(devName)
        // make array assign with index to merge with project array later
        this.getAllClassDev = this.arrModalColorDev.map((s, index) => ({
          id: index,
          color: s
        }));
        this.getDevPath();
      },
      getModalBgClassDesign(designName) {
        // get emit data from each project component class
        this.arrModalColorDesign.push(designName)
        // make array assign with index to merge with project array later
        this.getAllClassDesign = this.arrModalColorDesign.map((s, index) => ({
          id: index,
          color: s
        }));
        this.getDesignPath();
      },
      getDesignPath() {
        const designProjectPath = require.context('../components/design-projects', true, /^.*\.vue$/);
        this.generateProjectFileList(designProjectPath);

        //get modal design classes array and parse 
        let allModalClassDesign = JSON.parse(JSON.stringify(this.getAllClassDesign));
        // get curr project array list from generateProjectFileList()
        let currFileList = this.getFileList;

        this.combineArrays(currFileList, allModalClassDesign)
        //assign curr array to list after combined
        this.designList = this.combineArrayList
      },
      getDevPath() {
        const devProjectPath = require.context('../components/dev-projects', true, /^.*\.vue$/);
        this.generateProjectFileList(devProjectPath);

        //get modal dev classes array and parse
        let allModalClassDev = JSON.parse(JSON.stringify(this.getAllClassDev));
        // get curr project array list from generateProjectFileList()
        let currFileList = this.getFileList;

        this.combineArrays(currFileList, allModalClassDev)
        //assign curr array to list after combined
        this.devList = this.combineArrayList
      },
      generateProjectFileList(data) {
        //function make array list from proj folder files
        const requireFiles = data;
        let stringKey = requireFiles.keys()

        //generate number for box class
        let counter = 0;
        let FileList = stringKey.map((s, index) => ({
          id: index,
          number: "box-" + counter++,
          project: s.substring(2).replace('.vue', ''),
          title: s.substring(5).replace(/-/g, ' ').replace('.vue', ''),
          imageSrc: s.substring(5).replace(/-/g, '').replace('.vue', '-logo'),
        }));
        //pass data string to desingated function 
        // console.log("ff", FileList)
        this.getFileList = FileList;

      },
      combineArrays(arrayOne, arrayTwo) {
        //combine array from curr Project and curr modal classes 
        let combineArr3 = arrayOne.map((item, i) => Object.assign({}, item, arrayTwo[i]));
        //assign current array to data string
        this.combineArrayList = combineArr3
      },



    },
    mounted() {
      this.getDevPath();
      this.getDesignPath();

      document.onreadystatechange = () => {
        if (document.readyState == 'interactive' | document.readyState == 'loading') {
          this.loadingScreen = true;
        }

        if (document.readyState == 'complete') {
          this.loadWork = true;
          $(".loadingScreen").fadeOut();
          this.loadingScreen = false;
        }
      }

      // if (sessionStorage.getItem("iishorhor") !== "hasSession") {
      //   console.log("Detect work page")
      //   sessionStorage.setItem("iishorhor", "hasSession");
      //   // this.loadingScreen = true;
      //   setTimeout(function () {
      //     $(".loadingScreen").fadeOut();
      //     // this.loadWorkPage();
      //   }, 5000);

      // } else {
      //   this.loadingScreen = false;
      // }

    },

  }
</script>