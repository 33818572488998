var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contentContainer text-center   " }, [
      _c("h2", { staticClass: "text-1xl fontSemiBold" }, [
        _vm._v("USG Boral Website Revamp\n  ")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mt-6" }, [
        _vm._v("Worked with a group of Front-End and Back-End developers. "),
        _c("br"),
        _vm._v(" Using HTML, Less, JQuery, SwiperJs and AEM CMS.\n  ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content-wrapper  flex-wrap justify-center" }, [
        _c("p", { staticClass: "mt-6" }, [
          _vm._v(
            "Below are some of the workload where I built various types of component features that is reusable throughout the\n      website."
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticClass: "inline-block mt-6 md:w-1/2",
            attrs: {
              src: require("../../assets/images/usgboral/d-product-details-1.png")
            }
          })
        ]),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticClass: "inline-block mt-6 md:w-1/4",
            attrs: {
              src: require("../../assets/images/usgboral/m-product-details-1.png")
            }
          })
        ]),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticClass: "inline-block mt-6 md:w-1/2",
            attrs: {
              src: require("../../assets/images/usgboral/d-product-details-2.png")
            }
          })
        ]),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticClass: "inline-block mt-6 md:w-1/4",
            attrs: {
              src: require("../../assets/images/usgboral/m-product-details-2.png")
            }
          })
        ]),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticClass: "inline-block mt-6 md:w-1/2",
            attrs: {
              src: require("../../assets/images/usgboral/d-product-details-3.png")
            }
          })
        ]),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticClass: "inline-block mt-6 md:w-1/4",
            attrs: {
              src: require("../../assets/images/usgboral/m-product-details-3.png")
            }
          })
        ]),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticClass: "inline-block mt-6 md:w-1/2",
            attrs: {
              src: require("../../assets/images/usgboral/d-product-details-4.png")
            }
          })
        ]),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticClass: "inline-block mt-6 md:w-1/4",
            attrs: {
              src: require("../../assets/images/usgboral/m-product-details-4.png")
            }
          })
        ]),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticClass: "inline-block mt-6 md:w-1/2",
            attrs: {
              src: require("../../assets/images/usgboral/d-product-details-5.png")
            }
          })
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "mt-6" }, [
          _vm._v(
            ' Some components that requires filter function "Refine Your Search" where I will liase with Back-End developers to fetch data from the server.'
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticClass: "inline-block mt-6 md:w-1/4",
            attrs: {
              src: require("../../assets/images/usgboral/m-product-details-5.png")
            }
          })
        ]),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticClass: "inline-block mt-6 md:w-1/2",
            attrs: {
              src: require("../../assets/images/usgboral/d-product-details-6.png")
            }
          })
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "mt-6" }, [
          _vm._v(
            "Compare product where I target and fetch data of each product to combine into an array to be displayed."
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticClass: "inline-block mt-6 md:w-1/4",
            attrs: {
              src: require("../../assets/images/usgboral/m-product-details-6.png")
            }
          })
        ]),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticClass: "inline-block mt-6 md:w-1/2",
            attrs: {
              src: require("../../assets/images/usgboral/d-gallery-1.jpg")
            }
          })
        ]),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticClass: "inline-block mt-6 md:w-1/2",
            attrs: {
              src: require("../../assets/images/usgboral/d-gallery-2.jpg")
            }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }