<template>
    <div class=" border-2 border-black bottom-0 fixed px-1 py-1.5 z-20 m-2 cursor-pointer"  @click="changeFontBlack();">
        <p class="font-bold text-xs text-black">
            BW
        </p>
    </div>
</template>

<script>
    export default {
        methods: {
            changeFontBlack() {
                $(".contentWrapper").toggleClass("blackWhite");
            },
        }
    }
</script>