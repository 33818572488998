<template>
  <div class="contentContainer text-center   ">
    <h2 class="text-1xl leading-6 fontSemiBold"> EZ-Chord Guitar Game Application<br>
      <span class="text-tiny">(University Assignment Prototype Development For Testing Purposes)</span>
    </h2>

    <div class="content-wrapper  flex-wrap justify-center">
      
				<p class="mt-6">Designed and Developed the prototype with Photoshop and Adobe Flash to be test on android ios.</p>
				<img class="inline-block mt-6" src="../../assets/images/EZchord_behance.png"> 

    </div>

  </div>
</template>
<script>
  import clickMixin from '../../app/clickFunctions.js'
  export default {
    name: 'ezchord',
    mixins: [clickMixin],
    data() {
      return {}
    },
    methods: {},
    created() {
      this.$emit('getModalText', 'bgProjColors ezChordColor')
    }
  }
</script>