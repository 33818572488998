<template>
    <div class="modal">
        <div class="btnClose  " @click.prevent="closeModal">
            <div class="blockClose">
                <p>X</p>
            </div>
        </div>

        <!-- <h2> {{title}} </h2> -->
        <!-- <h3> {{message}} </h3> -->
        <div>
            <slot> </slot>
        </div>
    </div>
</template>


<script>
    export default {

        inheritAttrs: false,
        data() {
            return {
                getModalText: String,
            }
        },
        props: {

            title: {
                type: String
            },
            message: {
                type: String
            }
        },
        methods: {
            closeModal() {
                this.$emit('toggle-modal');
                $(".modal").scrollTop(0);
            },
        },
    }
</script>