<template>
  <div class="contentContainer text-center   ">
    <h2 class="text-1xl leading-6 fontSemiBold">Persil <br>
      <span class="text-tiny">(Detergent Campaign Launch.)</span>
    </h2>
    <div class="content-wrapper  flex-wrap justify-center">


      <p class="mt-6">Developed the website with HTML, CSS and Javascript.</p>


      <imgGifComponent>
        <img id="persil" @click="replayGif('persil')"
          src="https://media.giphy.com/media/bDGwk0T8kATW36ml7B/giphy.gif">
      </imgGifComponent>
      <vidComponent>
        <video poster="../../assets/images/persil.jpg" controls>
          <source src="../../assets/videos/persil.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p class="text-tiny">Desktop Version</p>

      <imgGifComponent>
        <img id="m-persil" @click="replayGif('m-persil')"
          src="https://media.giphy.com/media/aRmlmMPojAy6XcsWHt/giphy.gif">
      </imgGifComponent>
      <vidComponent>
        <video poster="../../assets/images/m-persil.jpg" controls>
          <source src="../../assets/videos/m-persil.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p class="text-tiny">Mobile Version</p>

    </div>


  </div>
</template>

<script>
  import clickMixin from '../../app/clickFunctions.js';
  import imgGifComponent from 'myComponents/imageGifComponent.vue';
  import vidComponent from 'myComponents/videoComponent.vue';

  export default {
    name: 'persil',
    mixins: [clickMixin],
    components: {
      'vidComponent': vidComponent,
      'imgGifComponent': imgGifComponent,
    },
    data() {
      return {}
    },
    created() {
      this.$emit('getModalText', 'bgProjColors persilColor')
    }
  }
</script>