var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contentContainer text-center   " }, [
      _c("h2", { staticClass: "text-1xl fontSemiBold" }, [
        _vm._v("UX Design Competition (Quick Buys)\n  ")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mt-6" }, [
        _vm._v("Create Interesting Fashion App")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content-wrapper  flex-wrap justify-center" }, [
        _c("p", [
          _vm._v(
            "Objective: Create a branded online shopping brand app, that allows user to mix and match items. Share comments\n      and suggestions with their peers."
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _c(
            "a",
            {
              attrs: {
                href:
                  "http://bdmzdt.axshare.com/#p=iphone_frame_for_desktop_view",
                target: "_blank"
              }
            },
            [_vm._v("Click Here")]
          ),
          _vm._v(" to view the prototype flow.")
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "inline-block mt-6 0xl:w-1/2",
          attrs: { src: require("../../assets/images/uxfashion-01.png") }
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "inline-block mt-6 0xl:w-1/2",
          attrs: { src: require("../../assets/images/uxfashion-02.png") }
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "inline-block mt-6 0xl:w-1/2",
          attrs: { src: require("../../assets/images/uxfashion-03.png") }
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "inline-block mt-6 0xl:w-1/2",
          attrs: { src: require("../../assets/images/uxfashion-04.png") }
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "inline-block mt-6 0xl:w-1/2",
          attrs: { src: require("../../assets/images/uxfashion-05.png") }
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "inline-block mt-6 0xl:w-1/2",
          attrs: { src: require("../../assets/images/uxfashion-06.png") }
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "inline-block mt-6 0xl:w-1/2",
          attrs: { src: require("../../assets/images/uxfashion-07.png") }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }