var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contentContainer text-center   " }, [
    _c("h2", { staticClass: "text-1xl fontSemiBold" }, [
      _vm._v("MSIG Mobile App Slice")
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "mt-6" }, [
      _vm._v(
        "Build with HTML, CSS, JQuery. Liasting with client's side Mobile developers."
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-wrapper  flex-wrap justify-center" },
      [
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "msig-mobile-one",
              src: "https://media.giphy.com/media/RxZqs6KGYBTK9VRDWy/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("msig-mobile-one")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-1/4" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/msig-mobile-one.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/msig-mobile-one.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "mt-10" }, [
          _vm._v(
            "Building features such as sliders , checkbox , accordions and forms."
          )
        ]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "msig-mobile-two",
              src: "https://media.giphy.com/media/l8hLpcgaHdeT0dwDVB/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("msig-mobile-two")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-1/4" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/msig-mobile-two.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/msig-mobile-two.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }