<template>
  <div class="contentContainer text-center  ">

    <h2 class="text-1xl fontSemiBold ">Dove Project (Phase 1 & 2) </h2>
    <p>Developed the website with HTML , CSS and Javascript.<br>Supported with Fullpage.js Plugin.</p>


    <div class="content-wrapper  flex-wrap justify-center">

      <h2 class="text-xl my-6">Phase One</h2>

      <imgGifComponent>
        <img id="doveph1" @click="replayGif('doveph1')"
          src="https://media.giphy.com/media/8MAGWUKDF1artAfhXg/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-6/12">
        <video poster="../../assets/images/doveph1.jpg" controls>
          <source src="../../assets/videos/doveph1.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Desktop Version</p>

      <p class="mt-6">Animation is scripted with JS and Fullpage Js <br>is used to control section scrolling.</p>

      <imgGifComponent>
        <img id="doveph1_mobile" @click="replayGif('doveph1_mobile')"
          src="https://media.giphy.com/media/X1ZyRwlkHNmeIdM4VU/giphy.gif">
      </imgGifComponent>
      <vidComponent >
        <video poster="../../assets/images/doveph1_mobile.jpg" controls>
          <source src="../../assets/videos/doveph1_mobile.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Mobile Version</p>


      <h2 class="mt-10 text-xl leading-6">Phase Two <br>
        <span class="text-tiny">(This website is a continuation of Dove phase 1 campaign.)</span>
      </h2>

      <imgGifComponent>
        <img id="dove_phase2-1" @click="replayGif('dove_phase2-1')"
          src="https://media.giphy.com/media/k9ij8e1CSGc7ZVCXJA/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-6/12">
        <video poster="../../assets/images/dove_phase2-1.jpg" controls>
          <source src="../../assets/videos/dove_phase2-1.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Desktop Version</p>


      <p class="mt-6">Animation is scripted with JS and Fullpage Js <br>is used to control section scrolling.</p>

  
      <imgGifComponent>
        <img id="dove_phase2-2" @click="replayGif('dove_phase2-2')"
          src="https://media.giphy.com/media/3U0lG2s8xMFlDTHPnJ/giphy.gif">
      </imgGifComponent>
      <vidComponent >
        <video poster="../../assets/images/dove_phase2-2.jpg" controls>
          <source src="../../assets/videos/dove_phase2-2.mp4" type="video/mp4">
        </video>
      </vidComponent>


      <p>Featured Pages : KOL Gallery Display , Book Details , Form Details.</p>
      <p class="mt-6">Manually coded the features of Gallery Display page with JQuery and CSS.</p>
      <p class="mt-3">Aligning the composition of text and image in the Book Detail's and Form pages to fit responsive
        screen.</p>

     
      <imgGifComponent>
        <img id="m-dove_ph2" @click="replayGif('m-dove_ph2')"
          src="https://media.giphy.com/media/rEnnW9ZNS9mg9NiqrU/giphy.gif">
      </imgGifComponent>
      <vidComponent >
        <video poster="../../assets/images/m-dove_ph2.jpg" controls>
          <source src="../../assets/videos/m-dove_ph2.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Mobile Version</p>


      <p class="mt-6">(Worked in a team of 2 liasing with backend developers.)</p>

    </div>


  </div>
</template>

<script>
  import clickMixin from '../../app/clickFunctions.js';
  import imgGifComponent from 'myComponents/imageGifComponent.vue';
  import vidComponent from 'myComponents/videoComponent.vue';

  export default {
    name: 'dove',
    mixins: [clickMixin],
    components: {
      'vidComponent': vidComponent,
      'imgGifComponent': imgGifComponent,
    },
    data() {
      return {

      }
    },
    methods: {},
    created() {
      this.$emit('getModalText', 'bgProjColors doveColor')
    }
  }
</script>