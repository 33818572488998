var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("TheNavigation"),
      _vm._v(" "),
      _c("router-view", { key: _vm.$route.path }),
      _vm._v(" "),
      _vm.landscapeMode
        ? _c(
            "div",
            {
              staticClass:
                " absolute bg-yellow-400 fadeIn h-screen top-0 w-full z-100"
            },
            [_vm._m(0)]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "flex h-full items-center justify-center relative text-center"
      },
      [
        _c("h3", { staticClass: "text-black fontSemiBold" }, [
          _vm._v("Sorry! "),
          _c("br"),
          _vm._v(" Please View in Portait Mode :)")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }