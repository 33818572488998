<template>
  <div class="contentContainer text-center   ">
    <h2 class="text-1xl  fontSemiBold"> Fatboy Website Revamp<br>
    </h2>
    <p>Developed the website with HTML , CSS and JQuery. <br> Supported with Animate On Scroll Library Plugin.</p>

    <div class="content-wrapper  flex-wrap justify-center">

      <p class="mt-6">Objective to create a parallax website with animation and transitions.</p>

      <imgGifComponent>
        <img id="fatboy-home" @click="replayGif('fatboy-home')"
          src="https://media.giphy.com/media/Yd6VTtx84w7jSCfIzQ/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-6/12">
        <video poster="../../assets/images/fatboy-home.jpg" controls>
          <source src="../../assets/videos/fatboy-home.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Desktop Version</p>

      <p class="mt-6">Use JQuery to control scrolling movements and animation of the images.</p>


      <imgGifComponent>
        <img id="m-fatboy-home" @click="replayGif('m-fatboy-home')"
          src="https://media.giphy.com/media/LMutZLA3x61K9WvZXv/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-1/4">
        <video poster="../../assets/images/m-fatboy-home.jpg" controls>
          <source src="../../assets/videos/m-fatboy-home.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Mobile Version</p>

      <p class="mt-8 text-xl"> Overall Journey of the Website.</p>

      <imgGifComponent>
        <img id="fatboy-two" @click="replayGif('fatboy-two')"
          src="https://media.giphy.com/media/eru6zIoN0n0UiKKXRz/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-6/12">
        <video poster="../../assets/images/fatboy-two.jpg" controls>
          <source src="../../assets/videos/fatboy-two.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Desktop Version</p>


      <imgGifComponent>
        <img id="m-fatboy-two" @click="replayGif('m-fatboy-two')"
          src="https://media.giphy.com/media/5GeDhrUno0BHN7YpZh/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-1/4">
        <video poster="../../assets/images/m-fatboy-two.jpg" controls>
          <source src="../../assets/videos/m-fatboy-two.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Mobile Version</p>

      <p class="mt-10">Visit <a class="text-white underline" href="https://www.fbgroup.asia/ " target="_blank">Fb Group
          Asia</a>
        website to see more. </p>

    </div>

  </div>
</template>
<script>
  import clickMixin from '../../app/clickFunctions.js'
  import imgGifComponent from 'myComponents/imageGifComponent.vue';
  import vidComponent from 'myComponents/videoComponent.vue';

  export default {
    name: 'fatboy',
    mixins: [clickMixin],
    components: {
      'vidComponent': vidComponent,
      'imgGifComponent': imgGifComponent,
    },
    data() {
      return {}
    },
    methods: {},
    created() {
      this.$emit('getModalText', 'bgProjColors fatboyColor')
    }
  }
</script>