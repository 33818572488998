<template>
  <div class="contentContainer text-center   ">
    <h2 class="text-1xl fontSemiBold">Mobile Payment Application Design
    </h2>
    <p class="mt-6">Inventory Cashier System Mobile Application Design</p>

    <div class="content-wrapper  flex-wrap justify-center">
      <p>Mobile cashier system that allows shop owners to easily keep track of their inventory and make transaction between customers.</p>

      <p>Adaption design base on wireframe.</p>

      <img class="inline-block mt-6 0xl:w-1/2" src="../../assets/images/ss-1.png">


      <p>Wireframe collaboerated with senior designer.</p>

      <img class="inline-block mt-6 lg:w-1/3" src="../../assets/images/ss-wireframe1-ipad.png">
      <img class="inline-block mt-6 lg:w-1/3" src="../../assets/images/ss-wireframe2-ipad.png">

      <p>Wireframes are Copyright.</p>


    </div>


  </div>
</template>
</style>

<script>
  import clickMixin from '../../app/clickFunctions.js'
  export default {
    name: 'MposIpad',
    mixins: [clickMixin],
    data() {
      return {}
    },
    methods: {},
    created() {
      this.$emit('getModalText', 'bgProjColors mPosIpadColor')
    }
  }
</script>