<template>
  <div class="contentContainer text-center   ">
    <h2 class="text-1xl fontSemiBold">Maxis Gempakzilla Promotional Website</h2>
    <p class="mt-6">Slicing build with HTML, CSS, JQuery.</p>

    <div class="content-wrapper  flex-wrap justify-center">      

      <div>
        <img  class="inline-block mt-6 md:w-1/2 "
          src="../../assets/images/gempakzilla.jpg">
      </div>   
     
      <p>Desktop Version</p>
      
      <div>
        <img  class="inline-block mt-10  "
          src="../../assets/images/m-gempakzilla.jpg">
      </div>
      
     
      <p>Mobile Version</p>


    </div>


  </div>
</template>
</style>

<script>
  import clickMixin from '../../app/clickFunctions.js'
  export default {
    name: 'maxisGempak',
    mixins: [clickMixin],
    data() {
      return {}
    },
    methods: {},
    created() {
      this.$emit('getModalText', 'bgProjColors maxisGempakColor')
    }
  }
</script>