import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Home from 'myPages/home.vue'
import Contact from 'myPages/contact.vue'
import About from 'myPages/about.vue'
import Work from 'myPages/work.vue'

// import modalDemo from 'myComponents/modalDemo.vue'


// export default new Router({
//   routes: [
//     {
//       path: '/',
//       name: 'Home',
//       component: Home
//     },
//     {
//       path: '/about',
//       name: 'About',
//       component: About
//     },
//     {
//       path: '/contact',
//       name: 'Contact',
//       component: Contact
//     },
//        {
//       path: '/work',
//       name: 'Work',
//       component: Work
//     },

//   ]
// })


// if want to use history mode need to check with server
// https://burnedikt.com/webpack-dev-server-and-routing/
export const routesArray = {
  // mode history to remove # in url
  // mode: 'history',
  linkExactActiveClass: 'nav-active-class',
  routes: [{
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/about',
      name: 'About',
      classType:'bg-blockYellow',
      component: About,
      //lazy loading method
      // but if build prod -  will have many main js files
      // component: () => import( /* webpackChunkName:"about" */ 'myPages/about.vue')
    },
    {
      path: '/work',
      name: 'Work',
      classType:'bg-blockRed',
      component: Work
      // component: () => import( /* webpackChunkName:"work" */ 'myPages/work.vue')
    },
    {
      path: '/contact',
      name: 'Contact',
      classType:'bg-blockGreen',
      component: Contact,
      // component: () => import( /* webpackChunkName:"contact" */ 'myPages/contact.vue')
    },
   

    //test making nav with same components getting data from json
    // {
    //   path: '/:id',
    //   name: 'DestinationDetails',
    //   component: () => import( /* webpackChunkName:"destination" */ 'myComponents/destinationDetails.vue')
    // },

    // {
    //   path: '/demo',
    //   name: 'Demo',
    //   component: modalDemo
    // },
  ]
}
export default new Router(routesArray)

// export let NavArray = routesArray;
// console.log("navvvv", routesArray)