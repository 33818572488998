<template>
  <div>

    <TheNavigation />
    <router-view :key="$route.path" />

    <div v-if="landscapeMode" class=" absolute bg-yellow-400 fadeIn h-screen top-0 w-full z-100">
      <div class="flex h-full items-center justify-center relative text-center">
        <h3 class="text-black fontSemiBold">Sorry! <br> Please View in Portait Mode :)</h3>
      </div>
    </div>
<!-- 
    <div v-if="loadingScreen" class="loadingScreen absolute bg-yellow-400 fadeIn h-screen top-0 w-full z-100">
      <p>loading screen</p>
    </div> -->


  </div>
</template>
<script>
  import TheNavigation from 'myComponents/TheNavigation.vue'
  export default {
    name: 'app',
    components: {
      TheNavigation
    },
    data() {
      return {
        landscapeMode: false,
        loadingScreen: true,
      }
    },
    methods: {

      handleOrientationChange() {
        const orientation = window.screen.orientation.type
        if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          if (orientation === "portrait-primary") {
            // portrait mode
            this.landscapeMode = false;
          } else if (orientation === "landscape-primary") {
            // landscape mode
            this.landscapeMode = true;
          }
        }
      }

    },
    watch: {
      detectMode: function () {
        // console.log("wwatch?")
        this.handleOrientationChange();

      }
    },
    mounted() {

      //onload detect change
      this.handleOrientationChange();
      //when user is rotating screen detect
      window.addEventListener("orientationchange", this.handleOrientationChange);

      // if (sessionStorage.getItem("iishorhor") !== "hasSession") {
      //   sessionStorage.setItem("iishorhor", "hasSession");

      //   setTimeout(function () {
      //     console.log("fade?")
      //     $(".loadingScreen").fadeOut();

      //   }, 5000);

      // } else {
      //   this.loadingScreen = false;
      // }

    },

  }
</script>

<style>
  /* #app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>