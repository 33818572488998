<template>
  <div class="contentContainer text-center   ">
    <h2 class="text-1xl fontSemiBold">D-Safe Drive Mode Car Interface Dashboard</h2>
    <p class="mt-6">Inventory Cashier System Mobile Application Design</p>

    <div class="content-wrapper  flex-wrap justify-center">
      <p>Futuristic Car Dashboard Design</p>

      <p> Objective: Design the functionality of futuristic cars dashboard.</p>



      <p>Wireframe collaboerated with senior designer.</p>

      <img class="inline-block mt-6 " src="../../assets/images/CFEPosterSubmit.jpg">
      <!-- <img class="inline-block mt-6 lg:w-1/3" src="../../assets/images/DeviceJpg.png"> -->
      <img class="inline-block mt-6 lg:w-1/2" src="../../assets/images/CFEPoster_Amend.jpg">


    </div>


  </div>
</template>
</style>

<script>
  import clickMixin from '../../app/clickFunctions.js'
  export default {
    name: 'dDrive',
    mixins: [clickMixin],
    data() {
      return {}
    },
    methods: {},
    created() {
      this.$emit('getModalText', 'bgProjColors dDriveColor')
    }
  }
</script>