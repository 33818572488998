var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [_vm._m(0), _vm._v(" "), _vm._m(1), _vm._v(" "), _c("btnBW")],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          " top-0 sm:top-2 fixed py-5 sm:py-4 text-center w-full z-40 bgColorBeige bg-opacity-100 sm:bg-opacity-0"
      },
      [
        _c("p", { staticClass: "text-green-700 font-bold sm:text-1xl" }, [
          _vm._v("Contact Me :D")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "customHeight flex items-center justify-center fadeIn " },
      [
        _c(
          "div",
          {
            staticClass:
              " pt-20 sm:pt-10 sm:pb-36  px-6 sm:px-3 text-center contactWrapper contentWrapper"
          },
          [
            _c("p", { staticClass: "text-red-600 font-bold mt-8" }, [
              _vm._v("Preferably")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-red-600 " }, [
              _vm._v(" kyann007@gmail.com"),
              _c("br"),
              _c("span", { staticClass: "text-xs fontBold" }, [
                _vm._v("(Promise To Reply)")
              ])
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-purple-700 font-bold mt-6" }, [
              _vm._v("I Am Feeling Adventurous!")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-purple-700" }, [
              _vm._v("Interested to try and take on new interactive projects!")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-green-700 font-bold mt-6" }, [
              _vm._v("Stay Connected With Me")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-green-700" }, [
              _c(
                "a",
                {
                  staticClass: "text-green-600",
                  attrs: {
                    target: "_blank",
                    href: "https://www.linkedin.com/in/kar-yann-16693a47/"
                  }
                },
                [_vm._v("Linkedin")]
              ),
              _vm._v(" . "),
              _c(
                "a",
                {
                  staticClass: "text-green-600",
                  attrs: {
                    target: "_blank",
                    href: "https://www.behance.net/karyann"
                  }
                },
                [_vm._v("Behance")]
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-red-700 mt-6" }, [
              _vm._v("Check out my CV for more details!")
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "text-yellow-500 font-bold",
                attrs: {
                  href:
                    "https://www.dropbox.com/scl/fi/cibjj9pss5qq8ojf7t229/HorKarYann_Front-End-Developer_2024.pdf?rlkey=jg9p8mj7fhap8mdcdqtvgfevx&st=9do6pr3s&dl=0",
                  target: "_blank"
                }
              },
              [_vm._v("View CV")]
            ),
            _c("br")
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }