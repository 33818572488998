var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contentContainer text-center   " }, [
      _c("h2", { staticClass: "text-1xl fontSemiBold" }, [
        _vm._v("UMW Pitching Project\n  ")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mt-6" }, [_vm._v("Redesign Website")]),
      _vm._v(" "),
      _c("div", { staticClass: "content-wrapper  flex-wrap justify-center" }, [
        _c("img", {
          staticClass: "inline-block mt-6 0xl:w-1/2",
          attrs: {
            src: require("../../assets/images/UMW_behance_proposal.png")
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }