<template>
  <div class="contentContainer text-center  ">

    <h2 class="text-1xl leading-6 fontSemiBold">Money Back Gurantee <br>
      <span class="text-tiny">(Detergent Launch Campaign)</span>
    </h2>

    <div class="content-wrapper  flex-wrap justify-center">

      <p class="mt-6">Developed the website with HTML, CSS and Javascript.</p>

     
      <imgGifComponent>
        <img id="mbg" @click="replayGif('mbg')"
          src="https://media.giphy.com/media/bcKuMSm2uceEQoRKmC/giphy.gif">
      </imgGifComponent>
      <vidComponent>
        <video poster="../../assets/images/mbg.jpg" controls>
          <source src="../../assets/videos/mbg.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Desktop Version</p>

      <imgGifComponent>
        <img id="m-mbg" @click="replayGif('m-mbg')"
          src="https://media.giphy.com/media/pnOgtRiICvsm2A8hSO/giphy.gif">
      </imgGifComponent>
      <vidComponent>
        <video poster="../../assets/images/m-mbg.jpg" controls>
          <source src="../../assets/videos/m-mbg.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Mobile Version</p>

    </div>

  </div>
</template>

<script>
  import clickMixin from '../../app/clickFunctions.js';
  import imgGifComponent from 'myComponents/imageGifComponent.vue';
  import vidComponent from 'myComponents/videoComponent.vue';

  export default {
    name: 'mbg',
    mixins: [clickMixin],
    components: {
      'vidComponent': vidComponent,
      'imgGifComponent': imgGifComponent,
    },
    data() {
      return {}
    },
    methods: {

    },
    created() {
      this.$emit('getModalText', 'bgProjColors mbgColor')
    }
  }
</script>