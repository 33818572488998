<template>
  <div class="contentContainer text-center   ">
    <h2 class="text-1xl fontSemiBold">UX Design Competition (Quick Buys)
    </h2>
    <p class="mt-6">Create Interesting Fashion App</p>

    <div class="content-wrapper  flex-wrap justify-center">
      <p>Objective: Create a branded online shopping brand app, that allows user to mix and match items. Share comments
        and suggestions with their peers.</p>

        				<p><a href="http://bdmzdt.axshare.com/#p=iphone_frame_for_desktop_view" target="_blank" >Click Here</a> to view the prototype flow.</p>

      <img class="inline-block mt-6 0xl:w-1/2" src="../../assets/images/uxfashion-01.png">
      <img class="inline-block mt-6 0xl:w-1/2" src="../../assets/images/uxfashion-02.png">
      <img class="inline-block mt-6 0xl:w-1/2" src="../../assets/images/uxfashion-03.png">
      <img class="inline-block mt-6 0xl:w-1/2" src="../../assets/images/uxfashion-04.png">
      <img class="inline-block mt-6 0xl:w-1/2" src="../../assets/images/uxfashion-05.png">
      <img class="inline-block mt-6 0xl:w-1/2" src="../../assets/images/uxfashion-06.png">
      <img class="inline-block mt-6 0xl:w-1/2" src="../../assets/images/uxfashion-07.png">


    </div>


  </div>
</template>
</style>

<script>
  import clickMixin from '../../app/clickFunctions.js'
  export default {
    name: 'qBuy',
    mixins: [clickMixin],
    data() {
      return {}
    },
    methods: {},
    created() {
      this.$emit('getModalText', 'bgProjColors qBuyColor')
    }
  }
</script>