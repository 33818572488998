var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contentContainer text-center   " }, [
    _c("h2", { staticClass: "text-1xl fontSemiBold" }, [
      _vm._v("Proton X70 Promotional Microsite ")
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "mt-6" }, [
      _vm._v("Build with HTML, CSS, JQuery and Sitecore CMS.")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-wrapper  flex-wrap justify-center" },
      [
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "proton-one",
              src: "https://media.giphy.com/media/OVaCea5Emu4fXXgFui/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("proton-one")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-6/12" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/proton-one.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/proton-one.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Desktop Version")]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "m-proton-one",
              src: "https://media.giphy.com/media/NUW8wLvm3IxtRxj8Is/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("m-proton-one")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-1/4" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/m-proton-one.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/m-proton-one.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Mobile Version")])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }