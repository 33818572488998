<template>
  <div class="contentContainer text-center   ">
    <h2 class="text-1xl fontSemiBold">Mobile Payment Application Design
    </h2>
    <p class="mt-6">Payment application design that allow users to make payment via credit card.</p>

    <div class="content-wrapper  flex-wrap justify-center">

      <p>Adaption design base on wireframe.</p>

      <img class="inline-block mt-6 0xl:w-1/2" src="../../assets/images/ss-iphone1.png">
      <img class="inline-block mt-6 0xl:w-1/2" src="../../assets/images/ss-iphone2.png">


      <p>Wireframe collaboerated with senior designer.</p>
      
      <div>
        <img class="inline-block mt-6 lg:w-1/3" src="../../assets/images/ss-wireframe1-mobile.png">
        <img class="inline-block mt-6 lg:w-1/3" src="../../assets/images/ss-wireframe2-mobile.png">
      </div>

      <p>Wireframes are Copyright.</p>


    </div>


  </div>
</template>
</style>

<script>
  import clickMixin from '../../app/clickFunctions.js'
  export default {
    name: 'Mpos',
    mixins: [clickMixin],
    data() {
      return {}
    },
    methods: {},
    created() {
      this.$emit('getModalText', 'bgProjColors mPosColor')
    }
  }
</script>