var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contentContainer text-center   " }, [
      _c("h2", { staticClass: "text-1xl leading-6 fontSemiBold" }, [
        _vm._v(" EZ-Chord Guitar Game Application"),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "text-tiny" }, [
          _vm._v(
            "(University Assignment Prototype Development For Testing Purposes)"
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content-wrapper  flex-wrap justify-center" }, [
        _c("p", { staticClass: "mt-6" }, [
          _vm._v(
            "Designed and Developed the prototype with Photoshop and Adobe Flash to be test on android ios."
          )
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "inline-block mt-6",
          attrs: { src: require("../../assets/images/EZchord_behance.png") }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }