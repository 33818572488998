<template>
  <div class="contentContainer text-center   ">
    <h2 class="text-1xl fontSemiBold">UMW Pitching Project
    </h2>
    <p class="mt-6">Redesign Website</p>

    <div class="content-wrapper  flex-wrap justify-center">


      <img class="inline-block mt-6 0xl:w-1/2" src="../../assets/images/UMW_behance_proposal.png">


    </div>


  </div>
</template>
</style>

<script>
  import clickMixin from '../../app/clickFunctions.js'
  export default {
    name: 'umw',
    mixins: [clickMixin],
    data() {
      return {}
    },
    methods: {},
    created() {
      this.$emit('getModalText', 'bgProjColors umwColor')
    }
  }
</script>