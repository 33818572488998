var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contentContainer text-center   " }, [
      _c("h2", { staticClass: "text-1xl fontSemiBold" }, [
        _vm._v("Maxis Gempakzilla Promotional Website")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mt-6" }, [
        _vm._v("Slicing build with HTML, CSS, JQuery.")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content-wrapper  flex-wrap justify-center" }, [
        _c("div", [
          _c("img", {
            staticClass: "inline-block mt-6 md:w-1/2 ",
            attrs: { src: require("../../assets/images/gempakzilla.jpg") }
          })
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Desktop Version")]),
        _vm._v(" "),
        _c("div", [
          _c("img", {
            staticClass: "inline-block mt-10  ",
            attrs: { src: require("../../assets/images/m-gempakzilla.jpg") }
          })
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Mobile Version")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }