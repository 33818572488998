var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contentContainer text-center   " }, [
      _c("h2", { staticClass: "text-1xl fontSemiBold" }, [
        _vm._v("Mobile Payment Application Design\n  ")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mt-6" }, [
        _vm._v("Inventory Cashier System Mobile Application Design")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content-wrapper  flex-wrap justify-center" }, [
        _c("p", [
          _vm._v(
            "Mobile cashier system that allows shop owners to easily keep track of their inventory and make transaction between customers."
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Adaption design base on wireframe.")]),
        _vm._v(" "),
        _c("img", {
          staticClass: "inline-block mt-6 0xl:w-1/2",
          attrs: { src: require("../../assets/images/ss-1.png") }
        }),
        _vm._v(" "),
        _c("p", [_vm._v("Wireframe collaboerated with senior designer.")]),
        _vm._v(" "),
        _c("img", {
          staticClass: "inline-block mt-6 lg:w-1/3",
          attrs: { src: require("../../assets/images/ss-wireframe1-ipad.png") }
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "inline-block mt-6 lg:w-1/3",
          attrs: { src: require("../../assets/images/ss-wireframe2-ipad.png") }
        }),
        _vm._v(" "),
        _c("p", [_vm._v("Wireframes are Copyright.")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }