var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contentContainer text-center   " }, [
    _c("h2", { staticClass: "text-1xl fontSemiBold" }, [_vm._v("MSIG Revamp")]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-wrapper  flex-wrap justify-center" },
      [
        _vm._m(1),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "msig-one",
              src: "https://media.giphy.com/media/kskNopraFjp3lZC0MS/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("msig-one")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-6/12" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/msig-one.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/msig-one.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Desktop Version")]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "m-msig-one",
              src: "https://media.giphy.com/media/DoCievnuAHgTXaNbw6/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("m-msig-one")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-1/4" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/m-msig-one.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/m-msig-one.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Mobile Version")]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "msig-two",
              src: "https://media.giphy.com/media/yJataDw7BAalLWhIXw/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("msig-two")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-6/12" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/msig-two.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/msig-two.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Desktop Version")]),
        _vm._v(" "),
        _c("p", { staticClass: "mt-6" }, [
          _vm._v("VueJs was use to pass data from each steps.")
        ]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "m-msig-two",
              src: "https://media.giphy.com/media/3VY95SX4md9OptdZ1D/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("m-msig-two")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-1/4" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/m-msig-two.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/m-msig-two.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Mobile Version")]),
        _vm._v(" "),
        _vm._m(2)
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-6" }, [
      _vm._v(
        "Worked with a group of Front-End developers and liasing with client's Back-End developers.\n    "
      ),
      _c("br"),
      _vm._v(" Using HTML, CSS, JQuery and VueJS (to build form to pass data).")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-6" }, [
      _vm._v("Build various types pages. "),
      _c("br"),
      _vm._v(
        "Challenge was to make the table display be mobile and desktop\n      friendly."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-10" }, [
      _vm._v("Visit "),
      _c(
        "a",
        {
          staticClass: "text-blue-900 underline",
          attrs: {
            href: "https://takeiteasy.msig.com.my/msigonline/",
            target: "_blank"
          }
        },
        [_vm._v("MSIG")]
      ),
      _vm._v(" website to see more. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }