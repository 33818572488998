var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contentContainer text-center " }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-wrapper  flex-wrap justify-center" },
      [
        _c("p", { staticClass: "mt-6" }, [
          _vm._v("Developed the website with HTML, CSS and Javascript.")
        ]),
        _vm._v(" "),
        _c("imgGifComponent", [
          _c("img", {
            attrs: {
              id: "magnum",
              src: "https://media.giphy.com/media/2guaybO7QPTHsisTeG/giphy.gif"
            },
            on: {
              click: function($event) {
                return _vm.replayGif("magnum")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("vidComponent", { staticClass: "md:w-6/12" }, [
          _c(
            "video",
            {
              attrs: {
                poster: require("../../assets/images/magnum.jpg"),
                controls: ""
              }
            },
            [
              _c("source", {
                attrs: {
                  src: require("../../assets/videos/magnum.mp4"),
                  type: "video/mp4"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Desktop Version")]),
        _vm._v(" "),
        _c("p", { staticClass: "mt-6" }, [
          _vm._v(
            "Developed the transitions and features of the Contest page with JQuery and CSS to flow smoothly.\n    "
          )
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "inline-block mt-6",
          attrs: { src: require("../../assets/images/m-magnum.jpg") }
        }),
        _vm._v(" "),
        _c("p", [_vm._v("Mobile Version")]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("p", { staticClass: "mt-6" }, [
          _vm._v("(Worked in a team of 2 liasing with backend developers.)")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "text-1xl leading-6 fontSemiBold" }, [
      _vm._v("Magnum Pink and Black "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text-tiny" }, [
        _vm._v("(New Magnum Flavor Launch Campaign)")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-sm" }, [
      _vm._v("Images\n      Source"),
      _c("br"),
      _vm._v(
        "http://www.kitepunye.com/event/magnum-pink-black-yang-mana-satu-mood-anda/"
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }