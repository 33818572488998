<template>
  <div class="contentContainer text-center   ">
    <h2 class="text-1xl fontSemiBold">CIMB Website Revamp
    </h2>
    <p class="mt-6">Worked with a group of Front-End and Back-End developers. <br> Using HTML, Tailwind, JQuery, VueJS,
      SwiperJs
      and AEM CMS.</p>

    <div class="content-wrapper  flex-wrap justify-center">
      <p class="mt-6">Below are some of the workload where I built various types of component features that is reusable
        throughout the
        website.</p>


      <imgGifComponent>
        <!-- <img id="cimb-one" @click="replayGif('cimb-one')" src="../../assets/images/gif/cimb-one.gif"> -->
        <img id="cimb-one" @click="replayGif('cimb-one')"
          src="https://media.giphy.com/media/JibSXEjaBOiBIMQb3r/giphy.gif">
      </imgGifComponent>

      <vidComponent class="md:w-6/12">
        <video poster="../../assets/images/cimb-one.jpg" controls>
          <source src="../../assets/videos/cimb-one.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Desktop Version</p>


      <imgGifComponent>
        <!-- <img id="m-cimb-one" @click="replayGif('m-cimb-one')" class="inline-block mt-6" src="../../assets/images/m-cimb-one.gif"> -->
        <img id="m-cimb-one" @click="replayGif('m-cimb-one')"
          src="https://media.giphy.com/media/d3RfLzpMFytKE8Z4TL/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-1/4">
        <video poster="../../assets/images/m-cimb-one.jpg" controls>
          <source src="../../assets/videos/m-cimb-one.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Mobile Version</p>


      <p class="mt-10">Building components that is both responsive and flexible to cater different types of layout. </p>


      <imgGifComponent>
        <img id="cimb-two" @click="replayGif('cimb-two')"
          src="https://media.giphy.com/media/twkWyAp6XDY4e3lXGG/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-6/12">
        <video poster="../../assets/images/cimb-two.jpg" controls>
          <source src="../../assets/videos/cimb-two.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Desktop Version</p>


      <imgGifComponent>
        <img id="m-cimb-two" @click="replayGif('m-cimb-two')"
          src="https://media.giphy.com/media/xQMdwt7842dkhMtWSG/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-1/4">
        <video poster="../../assets/images/m-cimb-two.jpg" controls>
          <source src="../../assets/videos/m-cimb-two.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Mobile Version</p>

      
      <imgGifComponent>
        <img id="cimb-three" @click="replayGif('cimb-three')"
          src="https://media.giphy.com/media/NQzMlcKpDPwkNMqYob/giphy.gif">
      </imgGifComponent>
      <vidComponent class="">
        <video poster="../../assets/images/cimb-three.jpg" controls>
          <source src="../../assets/videos/cimb-three.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Desktop Version</p>


      <imgGifComponent>
        <img id="m-cimb-three" @click="replayGif('m-cimb-three')"
          src="https://media.giphy.com/media/SWCL0VsxatSXjgmjUo/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-1/4">
        <video poster="../../assets/images/m-cimb-three.jpg" controls>
          <source src="../../assets/videos/m-cimb-three.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Mobile Version</p>



      <p class="mt-10">Visit <a class="text-white underline" href="https://www.cimb.com.my/en/personal/home.html"
          target="_blank">CIMB</a> website to see more. </p>

    </div>


  </div>
</template>
</style>

<script>
  import clickMixin from '../../app/clickFunctions.js'
  import imgGifComponent from 'myComponents/imageGifComponent.vue';
  import vidComponent from 'myComponents/videoComponent.vue';

  export default {
    name: 'p1cimb',
    mixins: [clickMixin],
    components: {
      'vidComponent': vidComponent,
      'imgGifComponent': imgGifComponent,
    },
    data() {
      return {}
    },
    methods: {},
    created() {
      this.$emit('getModalText', 'bgProjColors cimbColor')
    }
  }
</script>