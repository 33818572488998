//import all components in folder 

//ref to map dynamic import - not sure how it works yet - refer code below for test
//https://stackoverflow.com/questions/50023284/how-to-dynamically-load-a-vue-component-after-using-require-context

import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context('.', false, /.*\.vue$/)
requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    // Gets the file name regardless of folder depth
    camelCase( fileName.split('/').pop().replace(/\.\w+$/, ''))
  )


  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )

  //  console.log("DEVcomponenent", componentName ,    componentConfig )
  // console.log ("   componentConfig.defaul",    componentConfig)
})




//TEST MAP CODE
//how to use updated code?
// const requireContext = require.context('.', false, /.*\.vue$/)
// const dynamicComponents = requireContext.keys()
//     .map(file =>
//         [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
//     )
//     .reduce((components, [name, component]) => {
//         components[name] = component.default || component
//         return components
//       },{})
      
//       // Vue.component( dynamicComponents)
//       console.log("aaaaa",dynamicComponents.components)
   
