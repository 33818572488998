var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "hidden md:block delay-300 w-full",
        class: { navHidden: _vm.hideNav, fadeIn: _vm.fadeNav }
      },
      [
        _c("div", { staticClass: "flex pl-2 bgColorBeige h-16" }, [
          _c("div", { staticClass: "relative" }, [
            _c(
              "div",
              {
                staticClass: "home-icon lego-shape ",
                class: _vm.changeHomeColor
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "z-70 relative text-center block ",
                    attrs: { to: { name: "Home" } }
                  },
                  [_vm._v("Home")]
                )
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "nav",
          {
            staticClass:
              "z-10 naviWrapper absolute w-full bottom-0 flex justify-center pb-2.5 mt-3.5",
            class: { moveDown: _vm.fadeNav },
            attrs: { id: "nav" }
          },
          _vm._l(_vm.navlinks, function(item) {
            return _c("div", { key: item.name, staticClass: "relative" }, [
              _c(
                "div",
                {
                  staticClass:
                    " hover:bg-white block text-center mx-1.5 lego-shape duration-300 transition",
                  class: item.classType
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "block   my-1.5  py-2.5  w-32",
                      attrs: { to: item.path }
                    },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                ],
                1
              )
            ])
          }),
          0
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "hamburgerMenu md:hidden  bgColorBeige bg-opacity-100",
        class: { navHidden: _vm.hideNav }
      },
      [
        _c("div", { staticClass: " fixed ml-1.5 top-0  z-50 " }, [
          _c(
            "div",
            {
              staticClass: "  home-icon lego-shape ",
              class: _vm.changeHomeColor
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "text-center block",
                  attrs: { to: { name: "Home" } }
                },
                [_vm._v("Home")]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "lego-shape z-100  btnMenu fixed flex flex-col justify-center items-center",
            class: [
              _vm.btnMenuColor,
              { moveDown: _vm.fadeNav, iconClose: !_vm.showMobile }
            ],
            on: {
              click: function($event) {
                _vm.showMobile = !_vm.showMobile
                _vm.controlNaviColor()
              }
            }
          },
          [
            _c("div", { staticClass: "iconMenu" }),
            _vm._v(" "),
            _c("div", { staticClass: "iconMenu" }),
            _vm._v(" "),
            _c("div", { staticClass: "iconMenu" })
          ]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "m-bgMenu h-screen w-full fixed sm:hidden z-50  fadeIn ",
          class: { hidden: _vm.showMobile, fadeIn: _vm.showMobile },
          on: {
            click: function($event) {
              _vm.showMobile = !_vm.showMobile
            }
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "naviWrapper w-full fixed sm:hidden z-50 fadeIn",
            class: { hidden: _vm.showMobile }
          },
          _vm._l(_vm.navlinks, function(item) {
            return _c(
              "div",
              { key: item.name, staticClass: " m-btnNav relative w-2/5 " },
              [
                _c(
                  "div",
                  {
                    staticClass: "block lego-shape  text-center",
                    class: item.classType,
                    on: {
                      click: function($event) {
                        _vm.showMobile = true
                      }
                    }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "block   my-1.5  py-2.5 ",
                        attrs: { to: item.path }
                      },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  ],
                  1
                )
              ]
            )
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }