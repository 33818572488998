<template>
  <div class="contentContainer text-center   ">
    <h2 class="text-1xl fontSemiBold">EASI Microsite </h2>
    <p class="mt-6">Build with HTML, CSS, JQuery liasing with Back-End developers using AEM CMS.</p>

    <div class="content-wrapper  flex-wrap justify-center">
      <p class="mt-6">Build various types of components to be reusable throughout the website.</p>

      
      <imgGifComponent>
        <img id="easi-one" @click="replayGif('easi-one')"
          src="https://media.giphy.com/media/rj99OSovOuzEj0crNQ/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-6/12">
        <video poster="../../assets/images/easi-one.jpg" controls>
          <source src="../../assets/videos/easi-one.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Desktop Version</p>

       <imgGifComponent>
        <img id="m-easi-one" @click="replayGif('m-easi-one')"
          src="https://media.giphy.com/media/ZkHypleMmqNfI6g7wv/giphy.gif">
      </imgGifComponent>
      <vidComponent class="md:w-1/4">
        <video poster="../../assets/images/m-easi-one.jpg" controls>
          <source src="../../assets/videos/m-easi-one.mp4" type="video/mp4">
        </video>
      </vidComponent>
      <p>Mobile Version</p>

      <p class="mt-10">Visit <a class="text-white underline" href="https://www.easigypsum.com/" target="_blank">Easi</a>
        website to see more. </p>


    </div>


  </div>
</template>
</style>

<script>
  import clickMixin from '../../app/clickFunctions.js';
  import imgGifComponent from 'myComponents/imageGifComponent.vue';
  import vidComponent from 'myComponents/videoComponent.vue';
  export default {
    name: 'easi',
    mixins: [clickMixin],
    components: {
      'vidComponent': vidComponent,
      'imgGifComponent': imgGifComponent,
    },
    data() {
      return {}
    },
    methods: {},
    created() {
      this.$emit('getModalText', 'bgProjColors easiColor')
    }
  }
</script>